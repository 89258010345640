.dashboard {
  background-color: var(--color-dashboard-bg);
  display: flex;
  max-width: 100%;
  & > * {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
.content {
  overflow-y: auto;
}

.noTeams {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  p {
    color: var(--color-secondary);
  }
  a {
    margin-top: 1rem;
  }
}

.wizardTitle {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.8rem;
  text-align: center;
  @media screen and (max-width: 769px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.teamBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem 0;
  height: 100%;
}

.teamBoxHidden {
  display: flex;
  opacity: 0.4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem 0;
  height: 100%;
}

.teamList {
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1rem 2rem;
  justify-content: center;
}

.teamStartDescription {
  margin-top: 1rem;
}
.teamItem {
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease-in;
  padding: 1rem;
  &:hover {
    border-color: var(--color-primary);
  }
  span {
    font-weight: 600;
    display: block;
  }
  p {
    margin-top: 0.2rem;
    line-height: 1rem;
    color: var(--color-secondary);
    font-size: 0.785rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      max-width: 14px;
    }
  }
}
