.container {
  position: relative;
  display: inline-block;
}

.visible {
  display: block;
}

.hidden {
  position: absolute;
  top: calc(100% + 5px);
}
.righted {
  right: 0;
}

.lefted {
  left: 0;
}

.centered {
  left: 50%;
  transform: translateX(-50%);
}

.isAbsoluteBottom {
  bottom: 100%;
  left: 0;
  top: unset;
}
