.wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.label {
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-right: 0.3rem;
  color: var(--color-secondary);
}

.spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
}
.flex {
  display: flex;
  align-items: center;
}
.spinner:after {
  content: ' ';
  display: block;
  width: 10px;
  height: 10px;
  margin: 3px;
  margin-left: 5px;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
