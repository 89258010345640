.card {
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  &:hover {
    border-color: var(--color-primary);
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  p {
    font-size: 0.925rem;
    color: var(--color-secondary);
    margin-top: 0.3rem;
    line-height: 1.4;
  }
}
.avatarUsername {
  margin-left: 0.5rem;
}
.header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.headerActions {
  display: flex;
}
.author {
  display: flex;
  align-items: center;
}
.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0.5rem;
}
.tasksCount {
  font-size: 0.925rem;
  display: inline-block;
  border-radius: var(--border-radius-s);
  background: var(--tabs-bg);
  color: var(--color-grey);
  padding: 0 0.3rem;
}
.author {
  font-size: 0.925rem;
}
.public {
  color: var(--color-green);
}

.private {
  color: var(--color-red);
}
.hiddenMenu {
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  min-width: 100px;
  button {
    text-transform: capitalize;
    span {
      margin-left: 3px;
    }
    svg {
      margin-right: 5px;
      max-height: 14px;
    }
  }
  p {
    font-size: 0.7rem;
    color: var(--color-secondary);
    text-transform: uppercase;
    padding: 0.3rem 0.5rem;
    border-bottom: 1px solid var(--color-border-input);
  }
}
.hidden {
  right: 0;
  li {
    box-sizing: border-box;
    padding: 0.4rem 0.5rem;
    button {
      cursor: pointer;
      width: 100%;
    }
    button:hover {
      opacity: 0.6;
    }
  }
}
