.wrapper {
  box-sizing: border-box;
  h1 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  form {
    margin-top: 2rem;
    text-align: left;
  }
}
.avatarControl {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  img {
    margin-right: 1rem;
  }
  a {
    color: var(--color-primary);
  }
}
.formControl {
  margin-bottom: 1rem;
  textarea {
    padding-top: 0.5rem;
    line-height: 1.4;
    height: auto;
  }
}

.actions {
  margin-top: 1rem;
  button {
    margin-left: auto;
  }
}
