.wrapper {
  box-sizing: border-box;
  margin-top: 1rem;
}
.formControl {
  margin-bottom: 1rem;
  text-align: left;
  textarea {
    line-height: 22px;
    padding-top: 8px;
    min-height: 70px;
  }
}

.action {
  margin-top: 1rem;
  box-sizing: border-box;
  display: flex;
}
.action button + button {
  margin-left: 0.5rem;
}
.action button {
  flex-grow: 1;
  text-align: center;
  justify-content: center;
}
