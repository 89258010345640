.hero {
  margin-top: 1rem;
  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  p {
    color: var(--color-secondary);
    font-size: 1rem;
    max-width: 100%;
    max-width: 490px;
    margin: 0 auto;
  }
}
