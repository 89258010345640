.wrapper {
  background: var(--color-dashboard-bg-secondary);
  width: 300px;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 300px;
  min-width: 300px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 769px) {
    background-color: var(--color-card-bg);
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding: 0 0.5rem;
  }

  h1 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 1rem;
    color: var(--color-secondary);
  }
}
.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--color-dashboard-bg-secondary);
  margin-bottom: 1rem;
}
.catsList {
  overflow-y: auto;
}
.list {
  max-height: 100%;
  min-height: 473px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > a:has(.active) {
    border-color: red;
  }
}

.modelSection {
  margin-bottom: 2rem;
  h2 {
    font-size: 0.925rem;
    color: var(--color-secondary);
  }
}
.categoriesSection {
  margin-top: 0.5rem;
}

.active {
  color: var(--color-primary);
  background-color: var(--color-card-bg);
}
