.wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  margin-right: -5px;
  img {
    max-height: 16px;
    height: 16px;
    min-width: 16px;
    vertical-align: bottom;
    display: inline-block;
    max-width: 100%;
  }
  span {
    position: absolute;
    pointer-events: none;
    display: block;
    top: 0;
    white-space: nowrap;
    left: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    transition: all 0.3s ease;
    transform: translateY(-5px);
    border-radius: var(--border-radius-s);
    background: black;
    color: white;
    font-size: 0.725rem;
    line-height: 1;
    opacity: 0;
  }
  &:hover {
    span {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
