.badge {
  box-sizing: border-box;
  display: flex;

  span {
    display: inline-block;
    padding: 0.2rem 0.4rem;
    line-height: 1;
    border-radius: 16px;
    white-space: nowrap;
    //background-color: var(--color-text);
    //border: 1px solid var(--color-card-border);
    color: var(--color-secondary);
    font-size: 0.785rem;
  }
  a {
    color: var(--color-text) !important;
    &:hover {
      opacity: 0.6;
    }
  }
}

.flex {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  max-width: 100%;
  margin-right: 0.5rem;
  &::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }
}
