.modal {
  box-sizing: border-box;
  margin-top: 1rem;
  text-align: left;
}
.formControl {
  margin-bottom: 1rem;
  text-align: left;
  align-self: end;

  label {
    color: var(--color-text);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
}

.modalActions {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.pro {
  margin-bottom: 0.5rem;
  button {
    display: inline;
    padding: 0 !important;
    margin-right: 2px !important;
    span {
      color: var(--color-text) !important;
      border-bottom: 1px dashed var(--color-text);
    }
  }
}
