.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: var(--color-secondary);

  box-sizing: border-box;
  padding: 0.3rem 0.7rem;
  min-height: 38px;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  align-self: center;
  span {
    line-height: 1;
    font-size: 0.855rem;
  }
  svg {
    margin-right: 0.3rem;
  }
}

.checked {
  background: var(--color-green);
  color: var(--color-white);
  border-color: var(--color-green);
}
