.wrapper {
  box-sizing: border-box;
  width: 600px;
  max-width: 100%;
  h1 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  border-radius: var(--border-radius-s);
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 769px) {
    white-space: nowrap;
  }
}
.tableWrapper {
  max-width: 100%;

  overflow-x: auto;
  max-height: calc(100vh - 200px);
  @media screen and (max-width: 769px) {
    max-height: 50vh;
  }
}
.table td,
.table th {
  padding: 0.6rem 15px;
  text-align: left;
}
.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.tableActions {
  margin-top: 1rem;
  justify-content: center;
  display: flex;
}
.table tr:nth-child(even) {
  background-color: var(--color-dashboard-bg-secondary);
}

.table tr:hover {
  background-color: var(--color-dashboard-bg-secondary);
}

.table th {
  color: var(--color-white);

  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  background-color: var(--color-secondary);
}
