.wrapper {
  background: var(--color-dashboard-bg-secondary);
  width: 300px;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 300px;
  min-width: 300px;
  position: relative;
  overflow-y: auto;
  @media screen and (max-width: 769px) {
    max-width: 100%;
    width: 100%;
    background-color: var(--color-card-bg);
    padding: 0 1rem;
  }
  .category {
    font-weight: 500;
    font-size: 0.825rem;
  }
  h1 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0.3rem;
  }

  p {
    margin-top: 1rem;
  }
}

.backBlock {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  button {
    line-height: 1;
    &:hover {
      span {
        color: var(--color-text);
      }
    }
  }
  button span {
    margin: 0.5rem;
    color: var(--color-secondary);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.addToSpace {
  margin-left: auto;
  margin-right: 1rem;
  svg {
    width: 20px;
    height: 20px;
  }
}
.updated {
  margin-left: auto;
  color: #6b8dad;
  font-size: 0.825rem;
  display: none;
}

.bread {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  align-items: center;
  img {
    margin-right: 10px;
  }
  a {
    color: var(--color-secondary);
    line-height: 16px;
    span {
      padding: 0.1rem 0.2rem;
      color: var(--color-secondary);
      display: inline-block;
      border-radius: 20px;
      font-size: 0.795rem !important;
      line-height: 16px;
      //border: 1px solid var(--color-secondary);
      margin-right: 0.3rem;
    }
    &:hover {
      span {
        color: var(--color-text);
      }
    }
  }
  & > span {
    padding: 0 0.4rem;
    color: var(--color-secondary);
  }
}
.username {
  display: flex;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }
}
.usage {
  font-size: 0.875rem;
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  position: relative;
  color: var(--color-secondary);
  span {
    display: inline-block;
    padding: 0.2rem 0.4rem;
    line-height: 1;
    margin-left: 0.5rem;
    font-weight: 500;
    color: var(--color-primary);
    background-color: rgba(56, 139, 253, 0.1);
    border-radius: var(--border-radius-s);
  }
}

.markdown {
  font-size: 0.875rem;
  max-width: 100%;
  overflow-x: auto;
  line-height: 1.6;
  ol,
  ul {
    padding: 1.2rem;
    padding-top: 0;
    list-style: unset;
    padding-left: 1.2rem;
  }
  ol {
    list-style: numeric;
  }
  li + li {
    margin-top: 0.4rem;
  }
  a {
    color: var(--color-primary);
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 0.8rem;
    white-space: pre-line;
  }
  h1 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.1rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.875rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.4rem 12px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 0.4rem 12px;
      text-align: left;
      font-weight: 600;
      background-color: var(--color-secondary);
    }
  }
}
.desc {
  position: absolute;
  line-height: 1;
  bottom: -0.7rem;
  text-transform: uppercase;
  font-size: 0.6rem;
  right: 0;
}

.author {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  b {
    font-weight: 500;
  }
}

.info {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.examples {
  margin-top: 1.5rem;
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
}
.usageFirst {
  margin-right: 0.5rem;
}
.newTask {
  position: sticky;
  bottom: -16px;
  background: var(--color-dashboard-bg-secondary);
  left: -16px;
  right: -16px;
  box-sizing: border-box;
  padding: 1rem;
  border-top: 1px solid var(--color-border-input);
  @media screen and (max-width: 768px) {
    background-color: var(--color-card-bg);
  }
  button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.helpCredits {
  margin-left: -6px;
}

.credit {
  span {
    padding: 0.3rem 0.6rem;
    line-height: 1;
    border-radius: 16px;

    border-radius: var(--border-radius-s);
    color: var(--color-primary);
    background-color: rgba(56, 139, 253, 0.1);
    display: flex;
    font-size: 0.825rem;
    align-items: center;
    gap: 0.5rem;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.likes {
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border-radius: var(--border-radius-s);
  color: #6b8dad;
  font-size: 0.825rem;
}
.itemStats {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  gap: 0.5rem;
}
