.wrapper {
  box-sizing: border-box;
  margin: 1.5rem 0;
}
.header {
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
      margin-bottom: 0.2rem;
    }
  }
  p {
    color: var(--color-secondary);
  }
}

.controls {
  margin-top: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.tableActions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}

.filters {
  display: flex;
  align-items: flex-end;

  gap: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  & > div {
    min-width: 130px;
    max-width: 130px;
    @media screen and (max-width: 768px) {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
  input {
    width: 170px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
.newSpace {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.list {
  margin-top: 1rem;
  display: grid;
  gap: 1.7rem;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  padding-bottom: 5rem;
  grid-auto-rows: 0fr;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
}
.onboardingAction {
  margin-top: 1.5rem;
}
.onboarding {
  background-image: linear-gradient(
    125deg,
    hsl(239deg 77% 70%) 0%,
    hsl(240deg 68% 66%) 11%,
    hsl(241deg 62% 61%) 22%,
    hsl(242deg 56% 57%) 33%,
    hsl(243deg 52% 53%) 44%,
    hsl(244deg 52% 48%) 56%,
    hsl(245deg 58% 44%) 67%,
    hsl(245deg 66% 39%) 78%,
    hsl(246deg 78% 34%) 89%,
    hsl(247deg 100% 29%) 100%
  );
  color: var(--color-white);
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius-s);
  display: inline-block;
  h2 {
    font-weight: 500;
    font-size: 1.4rem;
  }
  p {
    margin-top: 0.5rem;
    font-size: 0.925rem;
    line-height: 1.4;
  }
}
