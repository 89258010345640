.sectionWrapper {
  width: 300px;
  box-sizing: border-box;
  max-width: 300px;
  min-width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid var(--color-border-input);
  & > div > ul {
    border-radius: 0;
    border-bottom: 1px solid var(--color-border-input);
    padding: 0;
    button {
      flex-grow: 1;
      text-align: center;
      box-shadow: none;
      border-radius: 0;
    }
  }
  @media screen and (max-width: 769px) {
    border-left: none;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}

.wrapper {
  //background: var(--color-dashboard-bg-secondary);
  width: 300px;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 300px;
  min-width: 300px;
  position: relative;
  height: 100%;
  @media screen and (max-width: 769px) {
    max-width: 100%;
    width: 100%;
    background-color: var(--color-card-bg);
    padding: 0 1rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  @media screen and (max-width: 769px) {
    margin-top: 1rem;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  button {
    &:hover {
      opacity: 0.7;
    }
    svg {
      max-height: 16px;
    }
  }
}

.space {
  cursor: pointer;
  display: flex;
  // margin-bottom: 0.5rem;
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  //border: 1px solid var(--color-border-input);
  padding: 0.5rem;
  width: 100%;
  background: var(--tabs-bg);
  margin-bottom: 0.5rem;
  &:hover {
    background-color: rgba(56, 139, 253, 0.1);
  }
  p {
    font-size: 0.825rem;
    color: var(--color-secondary);
    line-height: 18px;
    margin-top: 0.2rem;
  }
}
.spaceContentTitle {
  position: relative;
  font-size: 0.825rem;
  //color: var(--color-secondary);
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 0.3rem;
  svg {
    max-height: 22px;
    padding-right: 0.3rem;
    padding-top: 6px;
    position: relative;
    top: 2px;
  }
  span {
    line-height: 1.4;
  }
}
.openedSpace {
  //background-color: var(--color-dashboard-bg-userline);
}
.spaceWrapper {
  box-sizing: border-box;
  //margin-top: 1rem;
  border-radius: var(--border-radius-s);
}
.listSelectedSolutions {
  //margin-top: 1rem;
  & > a {
    margin-bottom: 0.3rem;
    font-weight: 500;
    padding: 0.4rem 0.8rem;
    border-radius: var(--border-radius-s);
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    &:hover {
      background-color: rgba(56, 139, 253, 0.1);
      color: var(--color-primary);
    }
  }
  h2 {
    font-size: 1rem;
  }
  p {
    font-size: 0.825rem;
  }
}
.count {
  font-size: 0.625rem;
  width: 18px;
  min-width: 18px;
  height: 16px;
  line-height: 16px !important;
  text-align: center;
  z-index: 1;
  border-radius: 4px;
  margin-top: 1px;
  text-align: center;
  display: block;
  color: var(--color-card-bg);
  background-color: var(--color-secondary);
  opacity: 0.4;
}
.activeSpace {
  //border: 1px solid rgba(56, 139, 253, 0.1);
  background-color: rgba(56, 139, 253, 0.1);
  //color: var(--color-primary);
  p {
    color: #fff;
  }
}
a.active {
  color: green !important;
}
.task {
  margin-bottom: 0.3rem;
  line-height: 20px;
  font-size: 0.875rem;
  display: flex;
  box-sizing: border-box;
  padding: 0.2rem 0.7rem;
  margin-left: 1rem;
  &:hover {
    button {
      opacity: 0.7;
    }
  }
  @media screen and (min-width: 768px) {
    button {
      opacity: 0;
    }
  }
}
.taskActive {
  color: var(--color-white);
  background: var(--color-primary);
}
.spaceActions {
  margin-left: auto;

  display: flex;
  width: 20px;
  max-width: 20px;
  max-height: 20px;
  button:hover {
    opacity: 0.6;
  }
}
.fix {
  svg {
    transform: rotate(90deg);
  }
}

.hiddenMenu {
  border-radius: var(--border-radius-s);
  background: var(--color-dashboard-bg-secondary);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  min-width: 100px;
  box-sizing: border-box;
  padding: 0.5rem;
  z-index: 1;
  position: relative;
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  button {
    text-transform: capitalize;
    width: 100%;
    span {
      margin-left: 3px;
      font-size: 0.875rem;
    }
    svg {
      margin-right: 4px !important;
      max-height: 14px;
    }
  }
}
.spacesList {
  margin-top: 1rem;
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
}

.noSpaces {
  color: var(--color-secondary);
  font-size: 0.875rem;
}

.personalSolution {
  box-sizing: border-box;
  padding: 0.3rem 0.8rem;
  max-width: 100%;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  margin-bottom: 1rem;
  display: block;
  &:hover {
    border-color: var(--color-primary);
  }
}
.search {
  button {
    margin-top: 0.5rem;
  }
}
.personalTitle {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}
.personalDescription {
  line-height: 1rem;
  white-space: nowrap;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-secondary);
  font-size: 0.785rem;
}
