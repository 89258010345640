.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  max-width: 100%;
  overflow-y: auto;
  & > * {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
  }
}

.content {
  box-sizing: border-box;
  padding: 2rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
  }
}

.form {
  margin-top: 1rem;
  button {
    margin-top: 8px;
  }
}
.itemUserInput {
  max-height: 44px;
  height: 44px;
  overflow: hidden;
}
.list {
  margin-top: 1rem;
  display: grid;
  gap: 1.7rem;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  padding-bottom: 10rem;
  grid-auto-rows: 0fr;
}
.item {
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  &:hover {
    border-color: var(--color-primary);
  }
}
.itemHeader {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid var(--color-card-border);
  a {
    &:hover {
      color: var(--color-primary);
    }
  }
  span {
    font-weight: 500;
    display: block;
    overflow: hidden;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    color: var(--color-secondary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.itemContent {
  height: 150px;
  max-height: 150px;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--color-secondary);
  span {
    font-family: inherit;
    background: rgba(135, 131, 120, 0.15);
    color: #eb5757;
    border-radius: 3px;
    font-size: 85%;
    padding: 0.2em 0.4em;
  }
}

.itemUserInput {
  line-height: 1.4;
  span {
    font-family: inherit;
    background: rgba(135, 131, 120, 0.15);
    color: #eb5757;
    border-radius: 3px;
    font-size: 85%;
    padding: 0.2em 0.4em;
  }
}
.subtitle {
  margin-top: 0.5rem;
  color: var(--color-secondary);
}
.itemContentImages {
  height: 150px;
  max-height: 150px;
  overflow: hidden;
  display: grid;
  gap: 0.2rem;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 0.2rem), 2fr));
}
.itemDate {
  font-size: 0.75rem;
  color: var(--color-secondary);
}
