.wrapper {
  background: var(--color-dashboard-bg-secondary);
  width: 260px;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 260px;
  min-width: 260px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 769px) {
    background-color: var(--color-card-bg);
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding: 0 0.5rem;
  }

  h1 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    padding-top: 0.3rem;
    position: sticky;
    background: var(--color-dashboard-bg-secondary);
    top: 0;
  }

  p {
    margin-top: 1rem;
    color: var(--color-secondary);
  }
}
.catsList {
  overflow-y: auto;
}
.list {
  margin-top: 1rem;
  max-height: 100%;
  min-height: 473px;
}

.modelSection {
  margin-bottom: 2rem;
  h2 {
    font-size: 0.925rem;
    color: var(--color-secondary);
  }
}
.categoriesSection {
  margin-top: 0.5rem;
  a {
    display: inline-block;
    font-size: 0.875rem;
    width: 100%;
    margin-bottom: 0.2rem;
    padding: 0.3rem 1rem;
    border-radius: var(--border-radius-s);
    box-sizing: border-box;
    span {
      margin-right: 0.3rem;
    }
    &:hover {
      background-color: var(--color-card-bg);
    }
  }
}

.active {
  color: var(--color-primary);
  background-color: var(--color-card-bg);
}

.blogItem {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  h3 {
    font-weight: bold;
    font-size: 0.925rem;
  }
  p {
    margin-top: 0;
    font-size: 0.875rem;
    line-height: 1.2rem;
    color: var(--color-secondary);
  }
}

.blogMD {
  font-size: 0.875rem;
  margin-top: 0.2rem;
  line-height: 1.2rem;
  p {
    color: var(--color-text);
  }
  ol,
  ul {
    padding: 0.3rem;
    padding-top: 0.3rem;
    list-style: unset;
    padding-left: 0.7rem;
    margin-left: 0.3rem;
  }
  ol {
    list-style: numeric;
  }
  li + li {
    margin-top: 0.1rem;
  }
}

.blogTime {
  margin-top: 0.2rem;
  font-size: 0.825rem;
  color: #6b8dad;
}
