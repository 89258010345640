.prices {
  margin: 0 auto;
  max-width: 1200px;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif;
  @media screen and (max-width: 768px) {
    max-height: 70vh;
    overflow-y: auto;
    //padding-top: 1rem;
    overscroll-behavior-block: contain;
  }
  ul {
    padding-bottom: 0 !important;
  }
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h4 {
    font-size: 2.2rem;
    text-align: left;
    line-height: 1.2;
    font-weight: 600;
    span {
      font-size: 1rem;
      color: var(--color-secondary);
      font-weight: 400;
    }
  }
}
.fullPrice {
  text-align: left;
  color: var(--color-secondary);
}
.dealWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  align-items: center;
}
.margin {
  height: 31px;
}
.oldPrice {
  text-decoration: line-through;
  color: var(--color-secondary);
  font-size: 1.2rem;
}
.deal {
  box-sizing: border-box;
  padding: 0.1rem 0.5rem;
  border-radius: 20px;
  display: flex;
  font-size: 0.825rem;
  color: var(--color-white);
  text-align: left;
  opacity: 0.6;
  background-color: var(--color-primary);
}
.hero {
  text-align: center;
}
.hint {
  color: var(--color-secondary);
  font-size: 0.725rem;
  line-height: 1rem;
}
.description {
  color: var(--color-secondary);
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.8rem;
  text-align: center;
}
.advantages {
  margin-bottom: 1rem;
  p {
    font-size: 0.825rem !important;
    margin-bottom: 0.2rem !important;
    //color: var(--color-secondary);
    svg {
      vertical-align: top;
      margin-right: 5px;
      max-width: 14px;
    }
  }
}
.common {
  margin: 1rem auto;
  h4 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }
}

.pricesWrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    // max-height: 50vh;
    // overflow-y: auto;
    padding-top: 1rem;
  }
}

.pricesBox {
  display: inline-block;
  position: relative;
  ul {
    display: flex;
    align-self: center;
  }
  @media screen and (max-width: 768px) {
    display: block;
    top: 0;
    position: sticky;
  }
}

.discount {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem;
  border-radius: 15px;
  font-weight: 500;
  line-height: 1;
  font-size: 0.685rem;
  color: rgb(205, 52, 32);
  background-color: rgb(248, 171, 158, 0.9);
  transform: rotate(-15deg);
  pointer-events: none;
  right: 52px;
  top: -9px;
}

.bonus {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  line-height: 1;
  padding: 0.2rem 0.4rem;
  border-radius: 15px;
  font-weight: 500;
  font-size: 0.685rem;
  top: -9px;
  right: -21px;
  color: var(--color-primary);
  background-color: rgba(56, 139, 253, 0.1);
  transform: rotate(-15deg);
  pointer-events: none;
}
.tab {
  margin-top: 1rem;
  p {
    text-align: center;
  }
}
.list {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: flex-start;
}
.calculation {
  margin-top: 0.3rem;
  color: var(--color-secondary);
  font-size: 0.875rem;
}
.actionWrapper {
  margin-top: auto;
  button {
    width: 100%;
    justify-content: center;
  }
}
.cancel {
  margin-top: 0.2rem;
  font-size: 0.7rem !important;
  text-align: center !important;
  color: var(--color-secondary);
}
.priceAmount {
  font-weight: 600;
  font-size: 1.4rem;
  margin-right: 0.25rem;
}
.creditsAmount {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding: 0.1rem 0.3rem;
  line-height: 1;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-primary);
  background-color: rgba(56, 139, 253, 0.1);
  border-radius: var(--border-radius-s);
}
.priceItem {
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius-s);
  min-width: 280px;
  background-color: var(--color-dashboard-bg);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--color-card-border);
  align-self: normal;
  display: flex;
  flex-direction: column;

  h3 {
    //font-weight: 600;
    //font-size: 0.5rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      text-transform: uppercase;
      color: var(--color-primary);
      padding: 0.1rem 0.3rem;
      line-height: 1;
      margin-left: 0.3rem;
      border-radius: var(--border-radius-s);
      font-size: 0.7rem;
      font-weight: 500;
      background-color: rgba(63, 136, 197, 0.2);
    }
  }
  p {
    text-align: left;
    font-size: 1rem;
    b {
      font-weight: 600;
    }
  }
}
.calculation {
  font-size: 0.925rem !important;
}
@media screen and (max-width: 768px) {
  .list {
    flex-direction: column;
  }

  .priceItem {
    width: 100%;
  }
}

.showcase {
  display: flex;
  //gap: 0.5rem;
  max-width: 872px;
  flex-wrap: wrap;
  margin: 1rem auto;
}

.showcaseItem {
  box-sizing: border-box;
  padding: 0.2rem;
  text-align: left;
  // border-radius: var(--border-radius-s);
  // background-color: var(--color-dashboard-bg);
  // border: 1px solid var(--color-card-border);
  // box-shadow: var(--card-shadow);
  max-width: 33%;
  width: 33%;
  flex-grow: 1;
  margin-bottom: 0.4rem;
  svg {
    margin-right: 5px;
    max-width: 14px;
  }
  span {
    font-weight: bold;
    font-size: 0.875rem;
    display: flex;
    margin-bottom: 0.2rem;
    align-items: center;
    line-height: 1.2;
  }
  p {
    color: var(--color-secondary);
    text-align: left;
    line-height: 1.3;
    font-size: 0.825rem !important;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
  }
}

.activeSub {
  border: 1px solid var(--color-primary);
}

.crypto {
  margin-top: 0.5rem;
  display: block;
}

sup {
  font-size: 0.525rem;
  opacity: 0.5;
  position: relative;
  top: -5px;
  right: -2px;
  line-height: 0;
}
