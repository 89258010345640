.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  & > * {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.revenueWrapper {
  max-width: 1000px;
  display: flex;
  gap: 2rem;
  & > div {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.short {
  max-width: 200px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
.summary {
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    p {
      line-height: 1.2;
    }
  }
  & > div {
    padding: 0.5rem;
    div {
      padding: 0;
    }
  }
  p {
    font-size: 0.675rem;
    color: var(--color-secondary);
    text-transform: uppercase;
  }
  span {
    font-weight: 600;
    font-size: 1.2rem;
  }
}
.help {
  margin-top: 3rem;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 1000px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    margin-top: 0.3rem;
    font-size: 0.875rem;
    color: var(--color-secondary);
    a {
      border-bottom: 1px dashed var(--color-secondary);
    }
  }
}
.content {
  box-sizing: border-box;
  // box-shadow: var(--card-shadow);
  position: relative;
  z-index: 1;
  overflow-y: auto;
  max-height: 100%;
  // background-color: var(--color-card-bg);
  padding: 1.4rem 2rem;
  padding-bottom: 10rem;
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
    max-width: 100%;
    padding-bottom: 10rem;
  }
  strong {
    font-weight: 600;
  }
  li + li {
    margin-top: 0.5rem;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p + p {
    margin-bottom: 1rem;
  }
}

.greet {
  margin-bottom: 2rem;
  h1 {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.listWrapper {
  box-sizing: border-box;
  margin-top: 2rem;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1000px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    & > div {
      overflow-x: auto;
    }
  }
}
.label {
  color: var(--color-secondary);
  font-size: 0.625rem;
  text-transform: uppercase;
}
.filters {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  max-width: 320px;
  input {
    min-width: 170px;
  }
}
.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  h2 {
    margin-bottom: 0;
    span {
      color: var(--color-secondary);
      font-size: 0.875rem;
      font-weight: 400;
      padding-left: 0.4rem;
    }
  }
  p {
    color: var(--color-secondary);
    font-size: 0.875rem;
  }
}
.category {
  a {
    font-size: 0.875rem;
    font-weight: bold;
    display: block;
  }
}
.greet {
  max-width: 1000px;
  p {
    margin-top: 0.5rem;
    color: var(--color-secondary);
  }
}
.task {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  white-space: nowrap;
}
.stats,
.edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    margin-bottom: 0.5rem;
  }
  button {
    height: 26px;
  }
}

.table {
  width: 100%;
  margin-top: -0.5rem;
  border-collapse: separate;
  border-spacing: 0 1rem;
  max-width: 100%;
  overflow-x: auto;
  thead th {
    padding: 0 0.5rem;
    button {
      display: flex;
      align-items: center;
    }
  }
  tbody tr {
    border-radius: var(--border-radius-s);
    background-color: var(--color-card-bg);
    //border: 1px solid var(--color-card-border);
    box-shadow: var(--card-shadow);
    border-left: 1px solid red;
    td {
      padding: 0.75rem;
      border: 1px solid var(--color-card-border);
      border-right: 0;
      border-left: 0;
      &:first-child {
        border-left: 1px solid var(--color-card-border);
      }
      &:last-child {
        border-right: 1px solid var(--color-card-border);
      }
    }
  }
}
.tableTitle {
  a {
    font-weight: 600;
    font-size: 0.925rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
  }

  p {
    font-size: 0.925rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
    color: var(--color-secondary);
  }

  a:hover {
    color: var(--color-primary);
  }
}

.head {
  color: var(--color-secondary);
  font-size: 0.825rem;
  text-transform: uppercase;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0.25rem;

  &:hover {
    color: var(--color-text);
  }
}
.date a {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-secondary);
  display: block;
  line-height: 18px;
  margin-bottom: 4px;
  &:hover {
    color: var(--color-text);
  }
}
.modalInstance {
  width: 400px;
  margin-top: 2rem;
}
.title {
  max-width: 240px;
  a {
    font-weight: 600;
    font-size: 0.925rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 0.925rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-secondary);
  }
}

.actions {
  margin-top: 2rem;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}
