.dashboard {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-surface);
  overflow-y: auto;
}

.content {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding-top: 2vh;
  height: 100%;
}

.form {
  position: fixed;
  z-index: 1;
  width: 100%;
  bottom: 0rem;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.topForm {
  transform: translateY(calc(-50vh + 40px));
}
.smallActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: var(--color-surface);
  padding-right: 0.2rem;
}
.listAction {
  z-index: 2;
  button {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    justify-content: center;
    background: var(--color-background-secondary);
    opacity: 0.9;
    div {
      background: #141414;
    }
    &:hover {
      opacity: 1;
    }
  }
  &:nth-child(2) {
    left: 34px;
  }
}
.list {
  display: flex;
  justify-content: center;
}
.listWrapper {
  max-width: 100%;
  width: 776px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-bottom: -20px;
  overflow-y: auto;
}
.nav {
  position: fixed;
  top: 2rem;
  left: 2rem;
  button {
    &:hover {
      opacity: 0.8;
    }
  }
  svg {
    width: 26px;
    height: 26px;
  }
}

.userArea {
  position: fixed;
  top: 2rem;
  right: 1rem;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actions {
  position: fixed;
  bottom: 4rem;
  left: 2rem;
  display: flex;
  z-index: 2;
  flex-direction: column;
  gap: 1rem;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 0.725rem;
    }
  }
  button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--color-background-secondary);
    justify-content: center;
    &:hover {
      opacity: 0.9;
    }
  }
}

.starter {
  position: fixed;
  bottom: calc(50vh + 160px);
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 0;
  flex-direction: column;
  width: 100%;
  img {
    position: relative;
    top: 5px;
  }
  p {
    display: none;
  }
  h1 {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: 600;
  }
}
.settingsItem {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: var(--color-secondary);
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}
.historySettingsContent {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  &:hover {
    .topCopy {
      opacity: 1;
    }
  }
}
.topCopy {
  opacity: 0;
  display: flex;
  gap: 1rem;
  padding-top: 5px;
  button {
    &:hover {
      opacity: 0.7;
    }
  }
}
.bottomCopy {
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 5px;
  align-self: baseline;
}
.contentWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
}
.contentMD {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  line-height: 1.6;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  padding-bottom: 5rem;
  &:last-child {
    padding-bottom: 15rem;
  }
  a {
    color: var(--color-primary);
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 1rem;
    white-space: pre-line;
    & > code {
      font-family: inherit;
      background: rgba(135, 131, 120, 0.15);
      color: #eb5757;
      border-radius: 3px;
      font-size: 85%;
      padding: 0.2em 0.4em;
    }
  }
  h1 {
    margin-bottom: 0.7rem;
    font-weight: 600;
    font-size: 1.4rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.925rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.6rem 15px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 12px 15px;
      text-align: left;
      font-weight: 600;
      background-color: var(--color-secondary);
    }
  }
}

.contentMD ul,
.contentMD ol {
  padding: 1.2rem;
  padding-top: 0;
  list-style: unset;
  padding-left: 3.2rem;
}
.contentMD ol {
  list-style: numeric;
}
.contentMD li + li {
  margin-top: 0.5rem;
}
.selectItem {
  position: relative;
  height: 26px;
  padding: 0 14px;
  padding-right: 30px;
  border-radius: 6px;
  //opacity: 0.8;
  display: flex;
  align-items: center;
  font-size: 0.785rem;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;
  box-sizing: border-box;
  // background-color: var(--color-surface);
  //background-color: var(--color-background-secondary);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-background-secondary);
  gap: 1rem;
  box-shadow: var(--active-tab-shadow);
  &:hover {
    opacity: 1;
    border: 1px solid var(--color-primary);
    .removeFromList {
      display: flex;
    }
  }
}
.activeItem {
  border: 1px solid var(--color-primary);
}
.removeFromList {
  top: 3px;
  right: 5px;
  display: none;
  position: absolute;
  border-radius: 50%;
  svg {
    max-width: 12px;
  }
}

@media screen and (max-width: 768px) {
  .starter {
    box-sizing: border-box;
    padding: 0 1rem;
    bottom: calc(50vh + 120px);
    h1 {
      font-size: 1.2rem;
    }
  }
  .actions {
    flex-direction: row;
    bottom: calc(15px + env(safe-area-inset-bottom, 15px));
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .nav {
    top: 1rem;
    left: 1rem;
  }
  .content {
    box-sizing: border-box;
    padding: 0 1rem;
    padding-top: 10vh;
  }
  .contentMD {
    font-size: 1rem;
  }
  .settingsItem {
    font-size: 1rem;
  }
  .list {
    padding-left: 0.5rem;
    margin-bottom: 20px;
  }
  .form {
    bottom: calc(78px + env(safe-area-inset-bottom, 78px));
  }
  .form > div {
    position: static !important;
  }
  .topForm {
    bottom: 0;
  }
}

.suggestList {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.suggestItem {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  cursor: pointer;

  max-width: 170px;
  text-align: center;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);

  line-height: 1.1rem;
  color: var(--color-secondary);
  font-size: 0.785rem;
  transition: all 0.3s ease-in-out;
  align-self: normal !important;
  &:hover {
    transform: translateY(-5px);
    color: var(--color-text);
  }
}

@media screen and (max-width: 768px) {
  .suggestList {
    display: none;
  }
}

.suggestWrapper {
  position: fixed;
  top: calc(150px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 0;
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.725rem;
    font-weight: 500;
    color: var(--color-secondary);
  }
}

.buyButton {
  display: block;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  border-radius: var(--border-radius-s);
  font-size: 0.725rem;
  color: var(--color-white);
  line-height: 1.2;
  background-color: #560cfc;
  border: 1px solid #560cfc;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.tryItem {
  // box-sizing: border-box;
  // background-color: var(--color-dashboard-bg);
  // border: 1px solid var(--color-card-border);
  // border-radius: var(--border-radius-s);
  // box-shadow: var(--card-shadow);
  //padding: 0.65rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 250px;
  margin-bottom: 5rem;
  p {
    color: var(--color-secondary);
    font-size: 0.875rem;
  }
}

.imagesBox {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  //grid-auto-rows: 0fr;
  img {
    width: 100%;
    height: auto;
  }
}
.imageItem {
  position: relative;
  &:hover a {
    opacity: 0.8;
  }
}
.downloadImage {
  position: absolute;
  opacity: 0;
  top: 10px;
  right: 10px;
  //opacity: 0.8;
  transition: all 0.3s ease-in-out;
  button {
    svg {
      margin-left: 0;
    }
    span {
      display: none;
    }
  }
  &:hover {
    opacity: 0.3 !important;
  }
}

@media screen and (max-width: 768px) {
  .content {
    padding-top: 2vh;
  }
}

.logo {
  margin-bottom: 2rem;
  display: block;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
  }
}
