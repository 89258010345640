.wrapper {
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-s);
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 0.3rem;
  display: block;
  font-size: 0.825rem;
  transition: all 0.3s ease;
  //background-color: var(--color-dashboard-bg);
  &:hover {
    background-color: rgba(56, 139, 253, 0.1);
  }
  &.active {
    //border: 1px solid var(--color-primary) !important;
  }
  .active {
    //border: 1px solid var(--color-primary) !important;
  }
  span {
    padding-right: 4px;
  }
}
strong {
  margin-right: 3px;
}
.active {
  //border: 1px solid var(--color-primary) !important;
  background-color: rgba(56, 139, 253, 0.1);
  //rgba(246 246 253, 1)
}

.date {
  font-size: 0.725rem;
  color: var(--color-secondary);
  line-height: 1;
  margin-top: 0.3rem;
  font-weight: 500;
}
.info {
  line-height: 1.3;
  max-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teamBox {
  //max-height: 35%;
  //min-height: 35%;
  //height: 35%;
  //overflow-y: auto;
  border-bottom: 1px solid var(--color-border-input);
  margin-bottom: 1rem;
}
.admin {
  svg {
    max-width: 10px;
    position: relative;
    top: 4px;
  }
}

.teamTitle {
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    background-color: var(--color-card-bg);
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    &:hover {
      opacity: 0.7;
    }
    svg {
      height: 12px;
      width: 12px;
    }
    span {
      font-size: 0.675rem;
      text-transform: capitalize;
      font-weight: 500;
      color: var(--color-secondary);
    }
  }
}
.teamItem {
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 0.3rem 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border-color: var(--color-primary);
  }
}

.teamMembers {
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  margin-top: -1rem;
}

.membersList {
  margin-top: -0.3rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.memberItemDetails {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  img {
    min-width: 20px;
  }
  span {
    font-weight: 500;
    font-size: 0.825rem;
    white-space: nowrap;
    overflow: hidden;
    max-width: 140px;
    text-overflow: ellipsis;
  }
}
.memberAction {
  margin-left: 1rem;
}

.memberItem {
  display: flex;
  align-items: center;
  &:hover {
    .memberAction {
      display: block;
    }
  }
}

.memberAction {
  margin-left: auto;
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
  }
  button {
    &:hover {
      opacity: 0.5;
    }
  }
}
