.wrapper {
  box-sizing: border-box;
  margin-top: 2rem;
  width: 330px;
  input {
    text-align: left;
  }
  button {
    margin-top: 3px;
    svg {
      max-width: 16px;
    }
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
.description {
  text-align: left;
  margin-top: 1rem;
  color: var(--color-secondary);
  font-size: 0.875rem;
}
