.dashboard {
  background-color: var(--color-dashboard-bg);
  display: flex;
  max-width: 100%;
  & > * {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
.content {
  overflow-y: auto;
}
