.wrapper {
  box-sizing: border-box;
  margin-top: 1rem;
}
.formControl {
  margin-bottom: 1rem;
  text-align: left;
  textarea {
    line-height: 22px;
    padding-top: 8px;
    min-height: 70px;
  }
}
.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.1rem;
  font-size: 0.725rem;
  color: var(--color-secondary);
}
