.wrapper {
  box-sizing: border-box;
  margin-top: 1rem;
}
.form {
  text-align: left;
}

.formControl {
  margin-bottom: 1rem;
  button {
    width: 100%;
    justify-content: center;
  }
}
.couponInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 0.2rem;
  span {
    font-weight: 400;
  }
}
