.card {
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
}

.title {
  box-sizing: border-box;
  padding: 1rem;
  border-bottom: 1px solid var(--color-card-border);
  h1 {
    font-weight: 600;
  }
}

.content {
  box-sizing: border-box;
  padding: 1rem;
}
