.taskItem {
  border-radius: var(--border-radius-s);
  //background-color: var(--color-card-bg);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  padding-top: 0.65rem;
  display: flex;
  flex-direction: column;
  position: relative;
  h2 {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.4;
  }

  &:hover {
    border-color: var(--color-primary);
    .headerActions {
      opacity: 1;
    }
  }
}
.tooltip {
  box-sizing: border-box;
  padding: 1px 8px;
  border-radius: var(--border-radius-s);
  background: #111;
  color: var(--color-white);
  position: absolute;
  top: 26px;
  font-size: 0.725rem;
  transition: all 0.3s ease;
  pointer-events: none;
  opacity: 0;
  white-space: nowrap;
  transform: translateY(-5px);
  right: 30px;
}
.taskCategory {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.author {
  display: flex;
  align-items: center;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1;

  span {
    padding-left: 0.5rem;
  }
}

.taskItemInfo {
  font-size: 0.925rem;
  line-height: 1.4;
  color: var(--color-secondary);
  margin-top: 0.5rem;
  // border-bottom: 1px solid var(--color-card-border);
  padding-bottom: 0.6rem;
  margin-bottom: 0.6rem;
}

.credit {
  span {
    padding: 0.26rem 0.5rem;
    line-height: 1;
    border-radius: 16px;
    border-radius: var(--border-radius-s);
    color: var(--color-primary);
    background-color: rgba(56, 139, 253, 0.1);
    display: flex;
    font-size: 0.825rem;
    align-items: center;
    gap: 0.5rem;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.likes {
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border-radius: var(--border-radius-s);
  color: #6b8dad;
  font-size: 0.825rem;
}
.headerActions {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 0.5rem;
  @media screen and (min-width: 768px) {
    opacity: 0;
  }
  a {
    margin-right: 0.3rem;
    button {
      padding: 2px 10px !important;
    }
    svg {
      max-width: 14px;
    }
  }
  button {
    padding: 2px;
    &:hover {
      opacity: 0.85;
    }
  }
  button svg {
    width: 20px;
    height: 20px;
  }
}
.footer {
  display: flex;
  align-items: center;
  margin-top: auto;
  justify-content: space-between;
}
.updated {
  margin-left: auto;
  color: #6b8dad;
  font-size: 0.825rem;
  display: none;
}
.itemTitle {
  h2 {
    font-size: 1.025rem;
    margin-bottom: 0 !important;
  }
}
.angleWrapper {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0;
  border-radius: var(--border-radius-s);
  overflow: hidden;
}
.toolTipWrapper {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0;
  &:hover {
    .tooltip {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.proAngle {
  position: absolute;
  bottom: -24px;
  right: -25px;
  width: 60px;
  height: 60px;
  button {
    outline: none !important;
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }
  svg {
    width: 50px;
    height: 50px;
  }
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
  & > svg {
    min-width: 20px;
    max-height: 18px;
    background: var(--color-primary);
    border-radius: var(--border-radius-s);
    margin-right: -5px;
  }
}

.itemStats {
  display: flex;
  margin-top: 0.7rem;
  align-items: center;
  gap: 0.5rem;
}
