.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  & > * {
    flex-grow: 1;
  }
  // & > div:nth-child(1) {
  //   background-color: #fff;
  // }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.content {
  box-sizing: border-box;
  padding: 0 2rem;
  max-height: 100vh;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    min-height: 100vh;
  }
}
