.pureCode {
  background: var(--color-dashboard-bg-secondary);
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  overflow-x: auto;
  max-width: 100%;
  border-radius: var(--border-radius-s);
  code {
    font-family: inherit;
    background: rgba(135, 131, 120, 0.15);
    color: #eb5757;
    border-radius: 3px;
    font-size: 85%;
    padding: 0.2em 0.4em;
  }
}
.lang {
  margin-right: 1rem;
  font-size: 0.875rem;
}
.copy {
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  padding-bottom: 0.3rem;
  display: flex;
  margin-bottom: -10px;
  border-radius: var(--border-radius-s) var(--border-radius-s) 0 0;
  position: relative;
  z-index: 0;
  justify-content: flex-end;
  background-color: rgba(56, 139, 253, 0.1);
  & + div {
    margin-top: 3px !important;
  }
  button {
    // cursor: pointer;
    font-family: 'SF Pro Display', Helvetica, Arial, sans-serif;
    padding: 0.05rem 0.5rem;
    color: var(--color-secondary);
    // border-radius: var(--border-radius-s);
    // display: flex;
    // background: var(--color-bg-button-secondary);
    font-size: 0.785rem;
  }
}

.coupone {
  max-width: 300px;
  font-size: 1rem;
  display: block;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif;
}
