.wrapper {
  box-sizing: border-box;
  margin-top: 1.5rem;
}

.formControl {
  text-align: left;
}

.actionFooter {
  margin-top: 1rem;
  button {
    width: 100%;
    text-align: center;
    justify-content: center;
    span {
      text-align: center;
    }
  }
}
.foundList {
  margin-top: 1rem;
}
.listItem {
  margin-bottom: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.listItemAuthor {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span {
    font-weight: 500;
  }
}
.listItemAction {
  margin-left: auto;
}

.teamTitle {
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  text-align: left;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
