.wrapper {
  box-sizing: border-box;
}

.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  & > * {
    flex-grow: 1;
  }
}
.feed {
  overflow-y: auto;
  max-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 5rem;
}
.content {
  box-sizing: border-box;
  padding: 0 2rem;
  max-width: 500px;
  margin: 0 auto;

  p + p {
    margin-top: 1rem;
  }
  p + h2 {
    margin-top: 1rem;
  }
  h2 {
    margin-bottom: 1rem;
    font-weight: 600;
  }
}
.description {
  margin: 0.5rem 0;
}
.header {
  box-sizing: border-box;
  padding: 2rem 1rem;

  h1 {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6;
    text-align: center;
  }
}

.blogItem {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  h3 {
    font-weight: bold;
    font-size: 1.125rem;
  }
  p {
    margin-top: 0;

    line-height: 1.2rem;
    color: var(--color-secondary);
  }
}

.blogMD {
  margin-top: 0.2rem;
  line-height: 1.2rem;
  p {
    color: var(--color-text);
  }
  ol,
  ul {
    padding: 0.3rem;
    padding-top: 0.3rem;
    list-style: unset;
    padding-left: 0.7rem;
    margin-left: 0.3rem;
  }
  ol {
    list-style: numeric;
  }
  li + li {
    margin-top: 0.1rem;
  }
}

.blogTime {
  margin-top: 0.2rem;
  font-size: 0.825rem;
  color: #6b8dad;
}
