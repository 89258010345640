.creditsWrapper {
  width: 400px;
  margin-top: 1rem;
  text-align: left;
  font-size: 0.925rem;
  @media screen and (max-width: 769px) {
    max-width: 100%;
    max-height: 50vh;
    overflow-y: auto;
  }
  b {
    font-weight: 600;
    margin-right: 0.2rem;
  }
  p {
    margin-bottom: 1rem;
    b {
      font-weight: 600;
      display: flex;
      margin-bottom: 0.2rem;
    }
  }
  ul {
    li {
      margin-bottom: 1rem;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

.pro {
  display: flex;
  align-items: center;
  img {
    max-height: 12px;
    margin-left: 5px;
  }
}
