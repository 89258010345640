.wrapper {
  box-sizing: border-box;
  padding: 2rem;
  h1 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.2rem;
  }
}
