.wrapper {
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  z-index: 3;
  margin-top: auto;
  @media screen and (max-width: 769px) {
    position: fixed;
    //bottom: 92px;
    bottom: calc(78px + env(safe-area-inset-bottom, 78px));
    left: 0;
    right: 0;
  }
}

.container {
  box-sizing: border-box;
  padding: 1.4rem 2rem;
  max-width: 840px;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 769px) {
    padding: 0 0.5rem;
  }
}

.form {
  position: relative;
  box-sizing: border-box;
  padding: 0.6rem 0;
  border-radius: var(--border-radius-s);
  margin-bottom: 1rem;
  background: #560cfc;
  @media screen and (max-width: 769px) {
    margin-bottom: 0;
    padding: 0.1rem 0;
  }
  .selectedModel {
    position: absolute;
    font-size: 0.515rem;
    bottom: -20px;
    left: 0;
    opacity: 0.9;
    color: var(--color-secondary);
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
  .tip {
    position: absolute;
    font-size: 0.515rem;
    bottom: -20px;
    right: 0;
    opacity: 0.9;
    color: var(--color-secondary);
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
  form {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    button {
      padding: 0.6rem;
      margin-right: 8px;
      align-self: flex-end;
      transition: opacity 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      span {
        font-size: 0.875rem;
        color: var(--color-white);
      }
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }
  textarea {
    background-color: #560cfc;
    border: none;
    padding-bottom: 0;
    box-shadow: none;
    outline: none !important;
    line-height: 22px;
    padding-top: 10px;
    color: var(--color-white);
    &:disabled {
      background: #560cfc;
    }
    @media screen and (max-width: 769px) {
      font-size: 14px;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.75);
    }
  }
  label {
    display: none;
  }
}
.settings {
  align-self: flex-end;
  margin-right: -20px;
  button {
    margin-right: 0 !important;
  }
}
.infoField {
  position: absolute;
  bottom: calc(100% + 2px);
  left: 0;
  font-size: 0.875rem;
  color: var(--color-secondary);
  display: flex;
  align-items: first baseline;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  button {
    display: inline;
    padding: 0 !important;
    margin-right: 2px !important;
    span {
      color: var(--color-secondary) !important;
    }
  }
  svg {
    margin-right: 2px;
    max-height: 12px;
  }
}

.maxInput {
  position: absolute;
  top: -3px;
  color: #fff;
  opacity: 0.75;
  left: 12px;
  font-size: 0.515rem;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.recent {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  position: absolute;
  bottom: 100px;
}
.listItem {
  box-sizing: border-box;
  padding: 0.2rem 1rem;
  border-radius: var(--border-radius-s);
  cursor: pointer;
}
.selected {
  background-color: var(--color-background-secondary);
}
.recentLabel {
  text-transform: uppercase;
  font-size: 0.625rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  color: var(--color-secondary);
}
.recentList {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.fastItem:hover .remove {
  display: block;
}

.fastItem {
  box-sizing: border-box;
  padding: 0.3rem 0.8rem;
  font-size: 0.925rem;
  max-width: 250px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  margin-bottom: 1rem;
  position: relative;
  &:hover {
    border: 1px solid var(--color-primary);
  }
  h3 {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    color: var(--color-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
