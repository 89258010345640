.container {
  width: 100%;
  position: relative;
}
.icon {
  position: absolute;
  bottom: 0.3rem;
  right: 0.6rem;
  pointer-events: none;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  display: block;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 1.25rem;
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
  background: var(--color-input-bg);
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  color: var(--color-text);
  box-shadow: var(--shadow-input);
}
.label {
  color: var(--color-text);
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.3rem;
  }
}

.description {
  color: var(--color-secondary);
  line-height: 1.4;
  font-size: 0.875rem;
  margin-bottom: 0.1rem;
}
.noBorder {
  border-color: transparent;
  box-shadow: none;
}

.select:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: -1px;
}

.error {
  border-color: var(--color-red);
}
