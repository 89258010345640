.wrapper {
  box-sizing: border-box;
  // padding: 1rem;
  box-shadow: var(--card-shadow);
  position: relative;
  z-index: 1;
  min-height: 100vh;

  //overflow-y: auto;

  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - 57px);
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 51px);
    margin-bottom: 10rem;
  }
}
.historyActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  button {
    max-height: 32px;
  }
}
.shareHistory {
  button {
    background: var(--color-bg-button-secondary);
    color: var(--color-text);
    height: 32px;
    border-color: var(--color-border-input);
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    font-size: 0.7125rem;
    span {
      display: none;
    }
  }
}
.imagesBox {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  //grid-auto-rows: 0fr;
  img {
    width: 100%;
    height: auto;
  }
}
.imageItem {
  position: relative;
  &:hover a {
    opacity: 0.8;
  }
}
.downloadImage {
  position: absolute;
  opacity: 0;
  top: 10px;
  right: 10px;
  //opacity: 0.8;
  transition: all 0.3s ease-in-out;
  button {
    svg {
      margin-left: 0;
    }
    span {
      display: none;
    }
  }
  &:hover {
    opacity: 0.3 !important;
  }
}
.userLine {
  box-sizing: border-box;
  background-color: var(--color-dashboard-bg-userline);
}
.model {
  font-weight: 600;
  text-transform: uppercase;
}
.avatar {
  display: flex;
  align-items: center;
  img:last-child {
    margin-left: -10px;

    background: var(--color-white);
    @media screen and (max-width: 768px) {
      margin-left: -17px;
    }
  }
  img {
    min-width: 30px;
    border: 2px solid var(--color-white);
  }
  span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: var(--color-dashboard-bg-secondary);
  }
}
.previewActions {
  display: flex;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 769px) {
    gap: 0.5rem;
    button {
      height: 26px;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }
}
.settingsItemLabel {
  text-transform: capitalize;
}
.settingsItem {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.contentWrapper {
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.streamStatusWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  button {
    color: var(--color-primary);
  }
}
.mobileBack {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  button {
    padding: 0.2rem;
    padding-left: 0;
  }
}
.backBlock {
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.mobileHeader {
  box-sizing: border-box;
  padding: 0.3rem 1rem;
  white-space: nowrap;
  font-size: 0.825rem;
  display: flex;
  gap: 0.5rem;
  //max-width: calc(100% - 30px);
  border: 1px solid var(--color-card-border);
  span {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    color: var(--color-secondary);
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.mobileHeaderTask {
  max-width: calc(100% - 30px);
}
.contentStreamLine {
  box-sizing: border-box;
  margin-top: 1rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 10rem;
  }
}

.endLine {
  text-align: center;
  font-weight: bold;
  width: 100%;
}

.contentWrapperTitle {
  font-size: 0.875rem;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    line-height: 1.4;
  }
  span {
    margin-left: 0.4rem;
    font-size: 0.785rem;
    font-weight: 400;
    color: var(--color-secondary);
  }
}

.contentWrapperTitle {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  min-height: 32px;
  button {
    &:hover {
      opacity: 0.7;
    }
  }
}

.contentLineWrapper {
  padding: 1.4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 1.4rem;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    padding: 1rem;
  }
}

.contentLine {
  box-sizing: border-box;
  padding-bottom: 3rem;
  flex-grow: 1;
}

.settingsContent {
  box-sizing: border-box;
  padding: 0.3rem;
  background-color: var(--color-card-bg);
  // border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);

  border-radius: var(--border-radius-s);

  max-height: 150px;
  overflow-y: auto;
}
.historySettingsContent {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  flex-wrap: wrap;
}
.historySettings {
  width: 100%;
}
.upgrade {
  background-color: rgba(56, 139, 253, 0.1);
  border-radius: var(--border-radius-s);
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.785rem;
  line-height: 1.2;
  button {
    display: inline;
    span {
      border-bottom: 1px dashed var(--color-text);
    }
  }
}
.historySettingsDate {
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  button {
    background-color: transparent;
  }
  @media screen and (max-width: 768px) {
    line-height: 1.4;
    margin-bottom: 0.3rem;
  }
  span {
    margin-left: 0.4rem;
    font-size: 0.785rem;
    font-weight: 400;
    color: var(--color-secondary);
  }
}

.userLineWrapper {
  padding: 1.4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 1.4rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    gap: 1rem;
  }
}

.streamline {
  position: relative;
  max-width: 720px;
  box-sizing: border-box;
  padding: 0 2rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.contentMD {
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  font-size: 1rem;
  a {
    color: var(--color-primary);
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 1rem;
    white-space: pre-line;
  }
  h1 {
    margin-bottom: 0.7rem;
    font-weight: 600;
    font-size: 1.4rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.925rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.6rem 15px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 12px 15px;
      text-align: left;
      font-weight: 600;
      background-color: var(--color-secondary);
    }
  }
}

.contentMD ul,
.contentMD ol {
  padding: 1.2rem;
  padding-top: 0;
  list-style: unset;
  padding-left: 3.2rem;
}
.contentMD ol {
  list-style: numeric;
}
.contentMD li + li {
  margin-top: 0.5rem;
}

.blinkIcon {
  display: inline-block;
  width: 4px;
  background-color: var(--color-primary);
  margin-left: 0.1rem;
  height: 12px;
  animation: cursor-blink 1s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.formWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.solutionWrapper {
  position: relative;
}
.solutionImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.8) 70%,
      rgba(0, 0, 0, 0.85) 80%,
      rgba(0, 0, 0, 0.9) 90%,
      rgba(0, 0, 0, 0.99) 100%,
      rgb(0, 0, 0) 100%
    );
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.solutionContainer {
  box-sizing: border-box;
  padding: 1rem 2rem;
  padding-top: 0;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  width: 100%;
  @media screen and (max-width: 769px) {
    margin-top: 1rem;
    padding: 1rem;
  }
}
.headerTopWrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
  @media screen and (max-width: 769px) {
    gap: 0.5rem;
  }
}
.solutionBreadcrumbs {
  line-height: 0;
  a {
    color: var(--color-secondary);
    font-weight: 500;
    font-size: 0.875rem;
  }
  span {
    display: inline-block;
    margin: 0.4rem;
    color: var(--color-secondary);
  }
}

.solutionBreadWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 2rem;
  @media screen and (max-width: 769px) {
    padding: 1rem 1rem;
  }
}
.solutionHero {
  margin-top: 0rem;
  text-align: center;

  h1 {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 2.6rem;
    @media screen and (max-width: 769px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 1rem;
  }
}
.solutionHeroTags {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.author {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  img {
    margin-right: 0.4rem;
  }
}
.limitErrorWrapper {
  padding: 1.4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}
.limitError {
  background-color: rgba(56, 139, 253, 0.1);
  border-radius: var(--border-radius-s);
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 0.885rem;
  line-height: 1.2;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  svg {
    min-width: 20px;
  }
  p {
    color: var(--color-secondary);
  }
  button {
    display: inline;
    span {
      border-bottom: 1px dashed var(--color-text);
    }
  }
}
.errorAction {
  margin-top: 0.5rem;
}
.errorTitle {
  font-weight: 500;
  font-size: 0.925rem;
  margin-bottom: 0.2rem;
  display: block;
}
.taskInfo {
  background-color: rgba(56, 139, 253, 0.1);
  border-radius: var(--border-radius-s);
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 0.785rem;
  line-height: 1.2;
  //display: flex;
  text-align: center;
  button {
    display: inline;
    span {
      border-bottom: 1px dashed var(--color-text);
    }
  }
}
.solutionStats {
  display: flex;
  justify-content: center;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    gap: 1.4rem;
  }
  & > div:first-child {
    svg {
      width: 26px;
    }
  }
}
.statItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.1rem;
  span {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      font-size: 0.875rem;
    }
    button {
      margin-left: 5px;
    }
    & > div {
      margin-right: 0.3rem;
    }
    svg {
      margin-right: 0.3rem;
      max-width: 16px;
    }
  }
}

.label {
  text-transform: uppercase;
  font-size: 0.625rem;
}
.exampleTitle {
  font-weight: 600;
  font-size: 1.2rem;
  //text-align: center;
  margin-bottom: 1rem;
}
.inputExample {
  margin-bottom: 1rem;
  h2 {
    font-weight: 600;
    font-size: 1.2rem;
    //text-align: center;
    margin-bottom: 1rem;
  }

  p {
    white-space: pre-wrap;
    svg {
      max-height: 14px;
      position: relative;
      top: 1px;
      padding-right: 0.3rem;
    }
  }
}
.example {
  margin-top: 4rem;
  max-width: 100%;
  h2 {
    font-weight: 600;
    font-size: 1.2rem;
    //text-align: center;
    margin-bottom: 1rem;
  }
}
