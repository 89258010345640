.container {
}

.tabContainer {
  box-sizing: border-box;
  padding: 0.125rem;
  background: var(--tabs-bg);
  display: flex;
  border-radius: var(--border-radius-s);
}
.tabContainer button {
  font-size: 0.875rem;
  line-height: 1.15rem;
  font-weight: 500;
  padding-bottom: 0.3375rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  color: var(--color-grey);
  background: transparent;
  border-color: transparent;
  cursor: pointer;
  padding-top: 0.3375rem;
  border-radius: var(--border-radius-s);
  a {
    color: unset !important;
  }
}
.tabContainer button + button {
  margin-left: 0.3rem;
}
.tabContainer button:hover {
  box-shadow: var(--shadow-button);
  color: var(--color-text);
}
button.tabActive {
  background: var(--tab-active-bg);
  color: var(--color-text);
  box-shadow: var(--active-tab-shadow);
}
