.wrapper {
  box-sizing: border-box;
  // margin: 1.5rem 0;
  // margin-top: 0;
}
.header {
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
      margin-bottom: 0.4rem;
    }
  }
  p {
    color: var(--color-secondary);
    @media screen and (max-width: 768px) {
      line-height: 1.4;
    }
  }
}
.previewActions {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.controls {
  margin-top: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.filters {
  display: flex;
  align-items: flex-end;

  gap: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  & > div {
    min-width: 130px;
    max-width: 130px;
    @media screen and (max-width: 768px) {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
  input {
    width: 170px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.list {
  margin-top: 1rem;
  display: grid;
  gap: 1.7rem;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  padding-bottom: 10rem;
  grid-auto-rows: 0fr;
}
.onboardingAction {
  margin-top: 1.5rem;
}
.author {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 769px) {
    font-size: 0.825rem;
  }
  span {
    display: flex;
    font-weight: 500;
    font-size: 0.925rem;
    @media screen and (max-width: 769px) {
      font-size: 0.825rem;
    }
  }
  img {
    margin-right: 0.5rem;
  }
}
.onboarding {
  background-image: linear-gradient(
    125deg,
    hsl(239deg 77% 70%) 0%,
    hsl(240deg 68% 66%) 11%,
    hsl(241deg 62% 61%) 22%,
    hsl(242deg 56% 57%) 33%,
    hsl(243deg 52% 53%) 44%,
    hsl(244deg 52% 48%) 56%,
    hsl(245deg 58% 44%) 67%,
    hsl(245deg 66% 39%) 78%,
    hsl(246deg 78% 34%) 89%,
    hsl(247deg 100% 29%) 100%
  );
  color: var(--color-white);
  box-sizing: border-box;
  padding: 1rem;
  border-radius: var(--border-radius-s);
  display: inline-block;
  h2 {
    font-weight: 500;
    font-size: 1.4rem;
  }
  p {
    margin-top: 0.5rem;
    font-size: 0.925rem;
    line-height: 1.4;
  }
}

.solutionBreadWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 2rem;
  @media screen and (max-width: 769px) {
    padding: 1rem 0rem;
  }
}
.solutionHero {
  margin-top: 0rem;
  text-align: center;

  h1 {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 2.6rem;
    @media screen and (max-width: 769px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 1rem;
  }
}

.solutionBreadcrumbs {
  line-height: 0;
  a {
    color: var(--color-secondary);
    font-weight: 500;
    font-size: 0.875rem;
  }
  span {
    display: inline-block;
    margin: 0.4rem;
    color: var(--color-secondary);
  }
}

.headerTopWrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
  @media screen and (max-width: 769px) {
    gap: 0.5rem;
  }
}
.solutionWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.solutionContainer {
  box-sizing: border-box;
  padding: 1rem 2rem;
  padding-top: 0;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: calc(90vh - 94px);
  @media screen and (max-width: 769px) {
    margin-top: 0;
    padding: 1rem 0;
    padding-top: 0;
  }
}

.starter {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  box-sizing: border-box;
  padding: 0.1rem 0.4rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  color: var(--color-text);
  font-size: 0.785rem;
  align-self: center;
}
