.wrapper {
  box-sizing: border-box;
  padding: 1rem 3rem;
  padding-top: 0.2rem;
  max-height: 100%;
  overflow-y: auto;
  @media screen and (max-width: 769px) {
    max-height: auto;
    overflow-y: initial;
    padding: 1rem;
  }
}
.catDescription {
  margin-top: 0.4rem;
  line-height: 1.2;
  font-size: 0.925rem;
  color: var(--color-secondary);
  padding-right: 1rem;
}
.catName {
  font-size: 0.875rem;
  color: var(--color-secondary);
  @media screen and (min-width: 1100px) {
    font-size: 1.2rem;
  }
}
.tagsListWrapper {
  overflow-x: hidden;
  position: relative;
}
.tagsList {
  // margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-bottom: 17px;
  @media screen and (max-width: 769px) {
    overflow-x: scroll;
    overflow-x: auto;
    max-width: 100%;
    flex-wrap: nowrap;
    max-width: calc(100vw - 2rem);
  }
  @media screen and (min-width: 769px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 15px;
    max-width: 100%;
  }
  button {
    white-space: nowrap;
  }
}
.sortIcon {
  position: relative;
  display: block;
  height: 33px;
  min-width: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  cursor: pointer;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    z-index: 1;
    appearance: none;
    border: none;
    width: 40px;
    height: 40px;
    color: transparent !important;
  }
}
.selectedSort {
  position: absolute;
  font-size: 0.525rem;

  left: 0;
  right: 0;
  text-align: center;
  bottom: 98%;
}
.tagItem:has(.active) {
  border: 1px solid red !important;
}
.tagItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 0.825rem;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  padding: 0.2rem 0.5rem;
  border-radius: 20px;
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  white-space: nowrap;
  &:has(.active) {
    border: 1px solid red !important;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    border-color: var(--color-primary);
  }
  span {
    padding-right: 0.2rem;
  }
}
.createOne {
  //background: var(--color-primary);
  //color: var(--color-white);
  border: 3px dashed var(--color-text);
  box-sizing: border-box;
  box-shadow: var(--card-shadow);
  border-radius: var(--border-radius-s);
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &:hover {
    border-color: var(--color-primary);
  }
  p {
    font-size: 1.325rem;
    line-height: 1.8rem;
    font-weight: 600;
  }
  div {
    margin-top: 1rem;
    color: var(--color-secondary);
  }
}
.titleContent {
  @media screen and (max-width: 1000px) {
    margin-bottom: 1rem;
  }
}
.title {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding-bottom: 0.3rem;
  padding: 1rem 0;
  justify-content: space-between;
  // border-bottom: 1px solid var(--color-border-input);
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
  }
  & > div {
    min-height: 51px;
  }
  h1 {
    font-size: 1.4rem;
    font-weight: 600;
    padding-top: 0.2rem;
    min-height: 27.2px;
  }
  p {
    font-size: 0.725rem;
    color: var(--color-secondary);
    line-height: 1.2;
    text-transform: uppercase;
  }
}

.feedActions {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  grid-column: 1/-1;
}

.list {
  margin-top: 1.5rem;
  display: grid;
  gap: 1.7rem;
  // max-height: 100%;
  // overflow-y: auto;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  padding-bottom: 10rem;
  grid-auto-rows: 0fr;
}

.activeCard {
  & > div {
    border-color: var(--color-primary);
  }
}
.listTabs {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deli {
  width: 2px;
  background-color: var(--color-border-input);
  opacity: 0.4;
  margin: 0 0.4rem;
}
.taskItem {
  h2 {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.2;
  }

  &:hover {
    border-color: var(--color-primary);
  }
}

.author {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.885rem;
  font-weight: 500;
  color: var(--color-secondary);
  line-height: 1;
  span {
    padding-left: 0.4rem;
  }
}

.taskItemInfo {
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--color-secondary);
}

.itemTitle {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  h2 {
    flex-grow: 1;
  }
}

.filtersArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  padding-bottom: 0.3rem;
  padding-top: 0.2rem;

  z-index: 1;
  background: var(--color-dashboard-bg-secondary);
  @media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 0.6rem;
    padding-bottom: 1rem;
    //justify-content: flex-start;
    input {
      width: 100%;
    }
    & > div {
      margin-top: 0;
    }
    & > div > div {
      width: auto;
    }
  }
}

.filters {
  display: flex;
  align-items: flex-end;

  gap: 1rem;
  & > div {
    min-width: 130px;
    max-width: 130px;
  }
  input {
    width: 170px;
  }
}
