.close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  button {
    background-color: rgba(56, 139, 253, 0.1);
    border-radius: 50%;
    padding: 0.2rem;
  }
  svg {
    width: 18px;
    height: 18px;
  }
}
