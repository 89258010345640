.dashboard {
  background-color: var(--color-dashboard-bg);
  display: flex;
  max-width: 100%;
  & > * {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
  }
}

.settings {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  box-sizing: border-box;
  padding: 1rem;
  border-right: 1px solid var(--color-card-border);
  h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
}

.form {
}
.formControl {
  margin-bottom: 1rem;
  label {
    color: var(--color-secondary);
    font-size: 0.875rem;
    display: block;
    margin-bottom: 0.2rem;
  }
  textarea {
    min-height: 150px;
    line-height: 20px;
    padding-top: 10px;
  }
  button {
    width: 100%;
    text-align: center;
    justify-content: center;
    span {
      font-size: 0.875rem;
      text-align: center;
    }
  }
}
.content {
  overflow-y: auto;
  box-sizing: border-box;
  padding: 1rem;
  flex-grow: 1;
  h1 {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.contentSmall {
  overflow-y: auto;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 1rem;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif;
  h1 {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.subtitle {
  color: var(--color-secondary);
  margin-top: 0.5rem;
}

.list {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.listItem {
  position: relative;
  aspect-ratio: 1 / 1;
  display: flex;
  border-radius: 8px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    .floatBox {
      opacity: 1;
    }
  }
}
.floatBox {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.99));
  justify-content: flex-end;
  flex-direction: column;
  right: 0;
  box-sizing: border-box;
  padding: 1rem;
  color: #fff;
  overflow-y: auto;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.download {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.copy {
  position: absolute;
  top: 1rem;
  left: 1rem;
  button {
    &:hover {
      opacity: 0.9;
    }
  }
}
.regen {
  margin-top: 0.5rem;
  svg {
    margin-left: 5px !important;
    max-width: 14px;
  }
}

.more {
  margin-top: 2rem;
  display: flex;
  margin-bottom: 10rem;
  justify-content: center;
}

.model {
  font-size: 0.625rem;
  text-transform: uppercase;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: space-between;
}
.inputText {
  font-size: 0.875rem;
}
.date {
  margin-top: 0.5rem;
  font-size: 0.785rem;
  opacity: 0.6;
}

.limitErrorWrapper {
  max-width: 800px;
  box-sizing: border-box;
  width: 100%;
}
.upgradeBlock {
  background: var(--color-green);
  color: #fff;
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 8px;
  font-size: 0.825rem;
  line-height: 20px;
  font-weight: 500;
  button {
    margin-bottom: 3px;
    span {
      color: #fff;
      font-weight: bold;
      font-size: 1rem;
      border-bottom: 1px dashed #fff;
    }
  }
}
.limitError {
  background-color: rgba(56, 139, 253, 0.1);
  border-radius: var(--border-radius-s);
  padding: 0.5rem;
  margin-top: 1rem;
  font-size: 0.885rem;
  line-height: 1.2;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  svg {
    min-width: 20px;
  }
  p {
    color: var(--color-secondary);
    margin-bottom: 0 !important;
  }
  button {
    display: inline;
    span {
      border-bottom: 1px dashed var(--color-text);
    }
  }
}
.errorAction {
  margin-top: 0.5rem;
}
.errorTitle {
  font-weight: 500;
  font-size: 0.925rem;
  margin-bottom: 0.2rem;
  display: block;
}

.shareHistory {
  position: absolute;
  top: 1rem;
  left: 3.2rem;
  z-index: 0;
  &:hover {
    svg {
      opacity: 0.8;
    }
  }
  svg {
    width: 18px;
    height: 18px;
  }
}

.modalWrapper {
  button {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}
