.spotlight {
  box-sizing: border-box;
}

.hotKey {
  box-sizing: border-box;
  padding: 0.1rem 0.3rem !important;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  color: var(--color-secondary);
  opacity: 0.8;
  font-size: 0.825rem;
}

.surface {
  background: var(--modal-surface);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  backdrop-filter: blur(4px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actionsSmall {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  svg {
    max-width: 12px;
    max-height: 15px;
  }
  span {
    font-size: 0.625rem;
    // text-transform: uppercase;
    line-height: 1.2;
    padding-top: 0.1rem;
    text-align: center;
  }
}
.closeButton {
  position: fixed;
  right: 1rem;
  top: 1rem;
  button {
    width: 2rem;
    height: 2rem;
    svg {
      width: 100%;
      min-width: 100%;
      height: 100%;
    }
  }
}
.container {
  display: inline-block;
  max-height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  //align-items: center;
  padding-top: 12%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding-top: 60px;
  }
}
.notFound {
  box-sizing: border-box;
  padding: 0.6rem 1rem;
}
.box {
  width: 500px;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 90vw;
  }
  button {
    width: 22px;
    height: 22px;
    margin-top: 0.75rem;
    svg {
      width: 100%;
      min-width: 100%;
      height: 100%;
    }
  }
  input {
    height: 50px;
    font-size: 1rem;
  }
  span {
    top: 0.9rem;
  }
}
.foundList {
  margin-top: 0.1rem;
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  max-height: 400px;
  overflow-y: auto;
}

.listItem {
  box-sizing: border-box;
  padding: 0.6rem 1rem;
  display: block;
  color: var(--color-text) !important;
  &:hover {
    background: var(--color-dashboard-bg-secondary);
  }
}
.selected {
  background: var(--color-dashboard-bg-secondary);
}
.listItemFooter {
  display: flex;
  gap: 1rem;
  align-items: center;
  p {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-secondary);
    font-size: 0.875rem;
  }
}

.listItemTitle {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-start;
  button {
    margin-top: 0;
    width: 60px;
    height: 26px;
    margin-bottom: 5px;
    svg {
      width: 12px;
      height: 12px;
      margin-left: -5px;
      padding-left: 0;
    }
  }
}

.author {
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  font-weight: 500;
  line-height: 1;
  img {
    min-width: 20px;
  }

  span {
    white-space: nowrap;
    padding-left: 0.5rem;
  }
}
