.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  & > * {
    flex-grow: 1;
  }
  // & > div:nth-child(1) {
  //   background-color: #fff;
  // }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.content {
  box-sizing: border-box;
  padding: 0;
  max-height: 100vh;
  position: relative;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    min-height: 100vh;
  }
}
.hiddenHistory {
  width: 30px;
  max-width: 30px;
  background-color: var(--color-border-input);
}
.mobileContent {
  padding: 0;
}
.wrapper {
  //background: var(--color-dashboard-bg-secondary);
  box-sizing: border-box;
  padding: 1rem;
  max-width: 260px;
  width: 260px;
  min-width: 260px;
  overflow-y: auto;
  display: flex;
  //justify-content: space-between;
  flex-direction: column;
  border-right: 1px solid var(--color-border-input);
  border-left: 1px solid var(--color-border-input);
  @media screen and (max-width: 769px) {
    background-color: var(--color-card-bg);
    width: 100%;
    max-width: 100%;
    border-right: none;
    border: none;
  }
  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0.3rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin-top: 1rem;
    color: var(--color-secondary);
  }
}
.month {
  font-size: 0.825rem;
  color: var(--color-secondary);
  margin-bottom: 0.3rem;
}

.tableActions {
  margin-top: 1rem;
  button {
    text-align: center;
    justify-content: center;
    span {
      text-align: center;
    }
  }
}
.chatList {
  overflow-y: auto;
  & > a {
    position: sticky;
    top: 0;
  }
  button {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.noItems {
  color: var(--color-secondary);
  font-size: 0.875rem;
}

.showHistory {
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 200px;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
  button {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
