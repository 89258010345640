.wrapper {
  //background: var(--color-dashboard-bg-secondary);
  box-sizing: border-box;
  padding: 1rem;
  max-width: 260px;
  width: 260px;
  min-width: 260px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--color-border-input);
  @media screen and (max-width: 769px) {
    background-color: var(--color-card-bg);
    width: 100%;
    max-width: 100%;
    border-right: none;
  }
  h1 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0.3rem;
  }

  p {
    margin-top: 1rem;
    color: var(--color-secondary);
  }
}
.historyTitle {
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.month {
  font-size: 0.825rem;
  color: var(--color-secondary);
  margin-bottom: 0.3rem;
}

.tableActions {
  margin-top: 1rem;
  button {
    text-align: center;
    justify-content: center;
    span {
      text-align: center;
    }
  }
}
.noItems {
  color: var(--color-secondary);
  font-size: 0.875rem;
}
.chatList {
  //overflow-y: auto;
  & > a {
    position: sticky;
    top: 0;
  }
  button {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}
