.wrapper {
  box-sizing: border-box;
}

.wizardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  box-sizing: border-box;
  padding: 2rem 3rem;
  max-height: 100%;
  overflow-y: auto;
  @media screen and (max-width: 769px) {
    padding: 2rem 1rem;
    max-width: 100%;
  }
  //di
}
.progressBox {
  @media screen and (max-width: 769px) {
    max-width: 100%;
  }
}
.wizardContent {
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  //justify-content: center;
  flex-grow: 1;
  width: 100%;
  // margin-top: -50px;
  // height: 100%;
  @media screen and (max-width: 769px) {
    padding: 2rem 0;
  }
  & > div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
  }
}
.line {
  box-sizing: border-box;
  width: 500px;
  height: 10px;
  border-radius: 10px;
  background: var(--tabs-bg);
  overflow: hidden;
  @media screen and (max-width: 769px) {
    max-width: 100%;
  }
}
.progress {
  background: var(--color-primary);
  height: 100%;
  min-width: 5% !important;
}

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  margin-top: 0.3rem;
}
.dropDownContainer {
  & > div {
    width: 100%;
  }
}
.hiddenDropdown {
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  width: 500px;
  padding: 0.3rem 0;
  box-shadow: var(--popup-shadow-sm);
  background-color: var(--color-dashboard-bg-secondary);
  @media screen and (max-width: 769px) {
    width: calc(100vw - 2rem);
  }
  button {
    width: 100%;
    box-sizing: border-box;
    padding: 0.15rem 1rem;
    transition: opacity 0.3s ease;
    svg {
      margin-left: auto !important;
    }
    &:hover {
      opacity: 0.5;
    }
    span {
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.3px;
    }
  }
}
.step {
  line-height: 1;
  p {
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
  span {
    font-size: 0.525rem;
    text-transform: uppercase;
    color: var(--color-secondary);
    display: block;
    line-height: 12px;
  }
}

.splitter {
  height: 14px;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0 0.7rem;
  opacity: 0.6;
  border-bottom: 1px dashed var(--color-secondary);
}
.stepActive {
  color: var(--color-primary) !important;
}

.stepActiveLast {
  color: var(--color-green) !important;
}
.wizardTitle {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.8rem;
  text-align: center;
  @media screen and (max-width: 769px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
.error {
  display: flex;
  gap: 1rem;
  border: 1px solid var(--color-red);
  border-radius: var(--border-radius-s);
  margin: 1rem auto;
  max-width: 500px;
  width: 500px;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
}
.wizardDescription {
  font-weight: 400;
  font-size: 1.1rem;
  max-width: 560px;
  margin: 0 auto;
  margin-top: 1rem;
  text-align: center;
  @media screen and (max-width: 769px) {
    font-size: 0.925rem;
  }
}
.aiModels {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
}

.aiModel {
  box-sizing: border-box;
  padding: 1rem;
  cursor: pointer;
  max-width: 215px;
  text-align: center;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  &:hover {
    border-color: var(--color-primary);
  }
}
.aiLogo {
  display: flex;
  justify-content: center;
  span {
    display: none;
  }
  img {
    height: 20px;
    max-height: 20px;
    position: relative;
    left: -5px;
  }
}

.aiName {
  margin-top: 0.5rem;
  font-weight: 600;
}

.aiDescription {
  margin-top: 0.2rem;
  line-height: 1rem;
  color: var(--color-secondary);
  font-size: 0.785rem;
}

.actionFooter {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.activeModel {
  border-color: var(--color-primary);
}
.left {
  margin-top: 1rem;
  text-align: center;
  color: var(--color-secondary);
  font-size: 0.85rem;
}
.tagsList {
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  max-width: 760px;
  button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 0.825rem;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    padding: 0.5rem;
    border-radius: var(--border-radius-s);
    background-color: var(--color-card-bg);
    border: 1px solid var(--color-card-border);
    box-shadow: var(--card-shadow);
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      border-color: var(--color-primary);
    }
    span {
      padding-right: 0.2rem;
    }
  }
}
.activeCheck {
  position: absolute;
  top: 0rem;
  right: 0.2rem;
}

.activeTag {
  border-color: var(--color-primary) !important;
}
.subDescription {
  color: var(--color-secondary);
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.2rem;
  margin-top: 0.5rem;
}

.splitDetails {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem;
  justify-content: center;
  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
}

.detailsForm {
  max-width: 500px;
  width: 380px;
}

.detailsCard {
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  width: 320px;
  min-height: 200px;
  padding-top: 0.65rem;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden;
}
.cardPreview {
  font-size: 0.585rem;
  color: var(--color-secondary);
  text-transform: uppercase;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
}

.itemStats {
  display: flex;
  margin-top: 0.7rem;
  align-items: center;
  gap: 0.5rem;
}
.itemTitle {
  h2 {
    font-size: 1.325rem;
    margin-bottom: 0 !important;
    font-weight: 600;
  }
}

.taskCategory {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.author {
  display: flex;
  align-items: center;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1;

  span {
    padding-left: 0.5rem;
  }
}

.taskItemInfo {
  font-size: 0.925rem;
  line-height: 1.4;
  color: var(--color-secondary);
  margin-top: 0.5rem;
  // border-bottom: 1px solid var(--color-card-border);
  padding-bottom: 0.6rem;
  margin-bottom: 0.6rem;
}

.credit {
  span {
    padding: 0.26rem 0.5rem;
    line-height: 1;
    border-radius: 16px;
    border-radius: var(--border-radius-s);
    color: var(--color-primary);
    background-color: rgba(56, 139, 253, 0.1);
    display: flex;
    font-size: 0.825rem;
    align-items: center;
    gap: 0.5rem;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.likes {
  display: flex;
  align-items: center;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border-radius: var(--border-radius-s);
  color: #6b8dad;
  font-size: 0.825rem;
}
.headerActions {
  display: flex;
  align-items: center;
  margin-left: auto;
  a {
    margin-right: 0.3rem;
    button {
      padding: 2px 10px !important;
    }
    svg {
      max-width: 14px;
    }
  }
  button {
    padding: 2px;
    &:hover {
      opacity: 0.6;
    }
  }
  button svg {
    width: 20px;
    height: 20px;
  }
}
.footer {
  display: flex;
  align-items: center;
  margin-top: auto;
  justify-content: space-between;
}

.details {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.1rem;
  font-size: 0.725rem;
  color: var(--color-secondary);
}

.formControl {
  margin-bottom: 1rem;
  text-align: left;
  align-self: end;
  textarea {
    min-height: 100px;
    line-height: 1.4;
    padding-top: 0.5rem;
  }
  label {
    color: var(--color-text);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
  }
}

.infoTitle {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.markdown {
  font-size: 0.875rem;
  max-width: 100%;
  overflow-x: auto;
  line-height: 1.6;
  ol,
  ul {
    padding: 1.2rem;
    padding-top: 0;
    list-style: unset;
    padding-left: 1.2rem;
  }
  ol {
    list-style: numeric;
  }
  li + li {
    margin-top: 0.1rem;
  }
  a {
    color: var(--color-primary);
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 0.8rem;
    white-space: pre-line;
  }
  h1 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.1rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.875rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.4rem 12px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 0.4rem 12px;
      text-align: left;
      font-weight: 600;
      background-color: var(--color-secondary);
    }
  }
}

.priceCard {
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-s);
  // background-color: var(--color-card-bg);
  // border: 1px solid var(--color-card-border);
  // box-shadow: var(--card-shadow);
  span {
    text-transform: uppercase;
    display: block;
    color: var(--color-secondary);
    font-size: 0.625rem;
  }
  p {
    font-size: 1.2rem;
    font-weight: 500;
  }
  strong {
    font-size: 0.685rem;
    color: var(--color-secondary);
  }
}
.priceDetails {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.price {
  input[type='range'] {
    all: revert;
    width: 100%;
  }
  input[type='text'] {
    width: 50px;
    max-width: 50px;
    &:disabled {
      opacity: 1;
    }
  }
  display: flex;
  gap: 1rem;
}
.highPrice {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.priceDescription {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  p {
    font-size: 0.755rem;
    color: var(--color-secondary);
    line-height: 18px;
    @media screen and (max-width: 768px) {
      line-height: 1.2;
    }
  }
}

.pricesDef {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.pricesDef {
  margin-top: 0.5rem;
}

.pricesDefItem {
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  color: var(--color-secondary);
  font-size: 0.725rem;
  text-transform: uppercase;
  span {
    font-size: 1rem;
    text-transform: lowercase;
    color: var(--color-text);
  }
}

.contextField {
  width: 500px;
  textarea {
    min-height: 100px;
  }
}
.promptControl {
  margin-top: 1rem;
  width: 550px;
  textarea {
    font-size: 1.1rem;
    line-height: 1.4rem;
    padding-top: 0.5rem;
    min-height: 100px;
    @media screen and (max-width: 769px) {
      font-size: 0.925rem !important;
    }
  }
}

.promptDetails {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 2rem;
  & > * {
    width: 100%;
  }
}

.formulaCreator {
  h2 {
    font-weight: 600;
    font-size: 1.1rem;
  }
  form {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    & > div {
      margin-bottom: 0;
      flex-grow: 1;
    }
  }
}
.promptControl {
  margin-top: 0rem;
  textarea {
    font-size: 1.1rem;
    line-height: 1.4rem;
    padding-top: 0.5rem;
    min-height: 100px;
  }
}

.addedFormulas {
  display: flex;
  align-items: end;
  gap: 0.5rem;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--color-card-border);
    padding-bottom: 2rem;
    &:last-child {
      border-bottom: none;
    }
  }
  button {
    background: transparent;
    border-color: transparent;
    height: 38px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    svg {
      margin: 0;
    }
  }
  & > div {
    margin-bottom: 0;
    flex-grow: 1;
  }
}

.promptTitle {
  font-weight: 600;
  font-size: 1.1rem;
  text-align: left;
}

.index {
  max-width: 16px;
  min-width: 16px;
}

.avaialbleFormulas {
  p {
    text-transform: uppercase;
    display: block;
    color: var(--color-secondary);
    font-size: 0.625rem;
  }
}

.formulaList {
  display: flex;
  gap: 0.5rem;
}

.formula {
  box-sizing: border-box;
  display: inline-block;
  padding: 0.15rem 0.7rem;
  background: var(--color-bg-button-secondary);
  color: var(--color-text);
  border: 1px solid var(--color-border-input);
  cursor: pointer;
  font-size: 0.875rem;
  position: relative;
  padding-right: 2rem;
  box-shadow: var(--card-shadow);
  &:hover {
    opacity: 0.7;
  }
  &:after {
    content: '+';
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    padding: 0.1rem 0.5rem;
    border-left: 1px solid var(--color-dashboard-bg-secondary);
    background: var(--color-bg-button-secondary);
    color: var(--color-text);
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;
  }
}

.previewTitle {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.clearForm {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  flex-grow: 0 !important;
  @media screen and (max-width: 769px) {
    margin-bottom: 5rem;
  }
  button {
    &:hover {
      opacity: 0.7;
    }
  }
  span {
    color: var(--color-secondary);
    margin: 0 3px;
  }
}

.formSettings {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  // flex-grow: 1;
  & > div {
    margin-bottom: 0;
  }
}

.promptError {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 1rem;
  color: var(--color-white);
  border-radius: var(--border-radius-s);
  background-color: var(--color-red);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
}

.promptErrorIcon {
  margin-right: 1rem;
}
.promptErrorContent {
  font-size: 0.875rem;
  color: var(--color-white);
}
.formPreview textarea {
  max-height: 38px !important;
  min-height: 38px !important;
}
.testPromptWrapper {
  width: 836px;
}
.testPromptWrapper textarea {
  min-height: unset;
}

.formWrapper {
  margin-top: 3rem;
}
.fullW {
  grid-column: 1/4;
  order: -1;
}

.form {
  position: relative;
  box-sizing: border-box;
  padding: 0.6rem 0;
  border-radius: var(--border-radius-s);
  margin-bottom: 1rem;
  background: #560cfc;
  form {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    @media screen and (max-width: 769px) {
      gap: 0;
    }
    button {
      padding: 0.6rem;
      margin-top: 3px;
      margin-right: 4px;
      span {
        font-size: 0.875rem;
      }
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }
  textarea {
    background-color: #560cfc;
    border: none;
    padding-bottom: 0;
    box-shadow: none;
    outline: none !important;
    line-height: 22px;
    padding-top: 10px;
    color: var(--color-white);
    &::placeholder {
      color: rgba(255, 255, 255, 1);
    }
    @media screen and (max-width: 769px) {
      font-size: 0.875rem !important;
    }
  }
  label {
    display: none;
  }
}
.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 60px;
    height: 60px;
  }
  h1 {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
  }
  div {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
  }
}
.foundList {
  margin-top: 1rem;
}
.listItem {
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.listItemAuthor {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span {
    font-weight: 500;
  }
}
.listItemAction {
  margin-left: auto;
}

.members {
  margin-top: 1rem;
}

.teamTitle {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
}
.members {
  margin-top: 1rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  padding-bottom: 0;
}

.wizardSteps {
  box-sizing: border-box;
  padding: 0.7rem 1rem;
  border-radius: 60px;
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.wizardStepsItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.wizardStepsItemName {
  font-size: 0.875rem;
  font-weight: 500;
}

.wizardStepsItemIndex {
  width: 26px;
  box-sizing: border-box;
  height: 26px;
  border-radius: 50%;
  background: var(--color-background-secondary);
  display: flex;
  font-size: 0.825rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.activeStep {
  .wizardStepsItemIndex {
    background-color: var(--color-primary);
  }
  .wizardStepsItemName {
    color: var(--color-primary);
  }
}

.finishicon {
  text-align: center;
  margin-bottom: 1rem;
  svg {
    width: 60px;
    height: 60px;
  }
}
