.dashboard {
  box-sizing: border-box;

  background: var(--color-dashboard-bg-primary);
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width: 769px) {
    overflow: initial;
    height: auto;
  }
}
.header {
  box-sizing: border-box;
  padding: 0.2rem 1rem;
  background-color: var(--color-dashboard-bg);
  box-shadow: var(--shadow-input);
  border-bottom: 1px solid var(--color-card-border);
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.pro {
  cursor: pointer;
  background-color: var(--color-green);
  color: var(--color-white);
  display: flex;
  width: 100%;
  margin-bottom: 3px;
  align-items: center;
  box-sizing: border-box;
  padding: 0.2rem 0.5rem;
  font-size: 0.725rem;
  margin-right: 1rem;
  border-radius: var(--border-radius-s);
  span {
    display: flex;
    align-items: center;
    line-height: 12px;
    font-weight: 500;
  }
  img {
    max-width: 30px;
    margin-left: 5px;
  }
}
.centerSide {
  flex-grow: 1;
  margin: 0 1rem;
  max-width: 600px;
  @media screen and (max-width: 1350px) {
    margin: 0 1rem;
  }
  @media screen and (max-width: 1310px) {
    margin: 0 1rem;
  }
}
.rightSide {
  display: flex;
  align-items: center;
}
.logo {
  color: var(--color-text) !important;
  img {
    max-height: 40px;
    max-width: 40px;
  }
  p {
    font-weight: 500 !important;
  }
}
.leftSide {
  display: flex;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    margin-left: 1.1rem;
    gap: 0.15rem;
    @media screen and (max-width: 1310px) {
      gap: 0.15rem;
    }
    li a {
      display: flex;
      align-items: center;
      font-size: 0.785rem;
      color: var(--color-text);
      white-space: nowrap;
      svg {
        margin-right: 0.1rem;
        max-height: 11px;
        @media screen and (max-width: 1310px) {
          margin-right: 0.1rem;
        }
      }
    }
  }
  a {
    color: var(--color-text);
  }
}
.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul li {
    a {
      //text-transform: uppercase;
      display: flex;
      align-items: center;
      //font-weight: 500;
      padding: 0 0.3rem;
      //color: var(--color-secondary);
      font-size: 0.785rem;
      border-radius: var(--border-radius-s);
      &:hover {
        background-color: rgba(56, 139, 253, 0.1);
      }
      // svg {
      //   display: none;
      // }
    }
  }
}
.dashboardContainer {
  display: flex;
  height: 100vh;
  width: 100vw;
  @media screen and (min-width: 769px) {
    height: calc(100vh - 57px);
  }
  @media screen and (max-width: 769px) {
    height: auto;
  }
  & > * {
    flex-grow: 1;
  }
}

body {
  background-image: none;
}
