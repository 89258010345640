.wrapper {
  position: fixed;
  bottom: 0;
  padding-bottom: calc(0.5rem + env(safe-area-inset-bottom, 0.5rem));
  left: 0;
  right: 0;
  box-sizing: border-box;
  background-color: var(--color-card-bg);
  //padding: 0 1rem;
  z-index: 2;
}

.inner {
  box-sizing: border-box;
  padding: 0.1rem 0.5rem;

  transform: all 0.8s ease;
  //border-radius: 50px;
  //background-color: var(--color-card-bg);
  border-top: 1px solid var(--color-card-border);
  //box-shadow: var(--card-shadow);
  overflow: hidden;
  position: relative;
}
.menu {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  align-items: center;
}
.animated {
  border-radius: 8px;
}
.content {
  box-sizing: border-box;
  padding-bottom: 2rem;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
}
.dynamicContent {
  display: flex;
  //position: absolute;
  justify-content: center;
  gap: 1rem;
  box-sizing: border-box;
  padding: 0.2rem 1rem;
  margin-bottom: 0.3rem;
  a {
    button {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }
  button {
    display: inline-block;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 20px;
    line-height: 16px;
    text-transform: uppercase;
    font-size: 0.725rem;
    font-weight: 500;
    padding: 0 0.5rem;
    background-color: var(--color-card-bg);
    border: 1px solid var(--color-card-border);
    box-shadow: var(--card-shadow);
  }
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    max-width: 12px;
  }
  span {
    font-size: 0.625rem;
    // text-transform: uppercase;
    line-height: 1.2;
    padding-top: 0.1rem;
    text-align: center;
  }
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
  }
  div {
    position: absolute;
    box-sizing: border-box;
    padding: 1px 0.1rem;
    border-radius: 15px;
    bottom: -0.1rem;
    color: #fff;

    //background-color: var(--color-primary);
  }
  svg {
    //width: 12px;
    //margin-right: 3px;
    width: 28px;
  }
  span {
    font-size: 0.6rem;
    text-transform: uppercase;
    line-height: 1;
    display: flex;
    height: 12px;
    align-items: center;
  }
}
.userMenu {
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  min-width: 120px;
}
.userMenuActions {
  margin-bottom: 0.5rem;
  // border-bottom: 1px solid var(--color-border-input);
  svg {
    margin-right: 0.5rem !important;
  }
  button {
    width: 100%;
    padding: 0.5rem 0.2rem;
    span {
      font-size: 0.9rem;
    }
    &:hover {
      background: var(--color-dashboard-bg-secondary);
    }
  }
}
.userContent {
  line-height: 1;
}

.userSummary {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  img {
    max-width: 30px;
    min-width: 30px;
    height: 30px;
  }
  border-bottom: 1px solid var(--color-border-input);
}
.email {
  font-size: 0.825rem;
  margin-top: 0.2rem;
  font-weight: 500;
}
.delimeter {
  margin: 0.5rem 0;
  height: 1px;
  background-color: var(--color-border-input);
}
.actionsFooter {
  border-top: 1px solid var(--color-border-input);
  padding-top: 0.5rem;
  & > div {
    margin-bottom: 0;
  }
  button span {
    color: var(--color-red);
  }
}
