.wrapper {
  box-sizing: border-box;
  margin-top: 0.5rem;
  text-align: left;
}

.preview {
  background: var(--color-dashboard-bg-secondary);
  font-size: 0.875rem;
  border-radius: var(--border-radius-s);
  box-sizing: border-box;
  padding: 0.5rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    font-size: 0.625rem;
    text-transform: uppercase;
    color: var(--color-secondary);
  }
}
.list {
  margin-top: 0.5rem;

  max-height: 270px;
  overflow-y: auto;
  @media screen and (max-width: 769px) {
    max-height: 50vh;
    overflow-y: auto;
  }
}
.preTitle {
  margin-top: 0.5rem;
}
.card {
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 0.4rem 0.5rem;
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  gap: 0.3rem;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  //color: var(--color-secondary);
  &:hover {
    border-color: var(--color-primary);
  }
  span {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    max-height: 14px;
    position: relative;
    top: 2px;
  }
}

.selected {
  border-color: var(--color-primary);
}

.action {
  margin-top: 1rem;
  button {
    width: 100%;
    margin-top: 0.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
