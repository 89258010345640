.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  & > * {
    flex-grow: 1;
  }
  @media screen and (max-width: 769px) {
    max-width: 100%;
  }
}

.help {
  margin-top: 2rem;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 1000px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    margin-top: 0.3rem;
    font-size: 0.875rem;
    color: var(--color-secondary);
    a {
      border-bottom: 1px dashed var(--color-secondary);
    }
  }
}
.content {
  box-sizing: border-box;
  // box-shadow: var(--card-shadow);
  position: relative;
  z-index: 1;
  overflow-y: auto;
  max-height: 100%;
  // background-color: var(--color-card-bg);
  padding: 1.4rem 2rem;
  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
    max-width: 100%;
    padding-bottom: 10rem;
  }
  strong {
    font-weight: 600;
  }
  li + li {
    margin-top: 0.5rem;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p + p {
    margin-bottom: 1rem;
  }
}
.intro,
.started,
.trend {
  max-width: 1000px;
}

.fastContainerBox {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 1000px;
  @media screen and (max-width: 769px) {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    max-width: 100vw;
    //flex-wrap: initial;
  }
}
.fastItem:hover .remove {
  display: block;
}
.remove {
  position: absolute;
  box-sizing: border-box;
  padding: 1rem 1rem;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  display: none;
  button {
    padding: 0.3rem;
    &:hover {
      opacity: 0.6;
    }
  }
}
.fastItem {
  box-sizing: border-box;
  padding: 0.3rem 0.8rem;
  font-size: 0.925rem;
  max-width: 250px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  margin-bottom: 0.1rem;
  position: relative;

  @media screen and (max-width: 769px) {
    // max-width: 100%;
    // width: 100%;
  }
  &:hover {
    border: 1px solid var(--color-primary);
  }
  h3 {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    color: var(--color-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.balance {
  margin-top: 1.5rem;
  max-width: 1000px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  button {
    margin-left: auto;
  }
  div {
    display: flex;
    align-items: center;
    button {
      margin-top: 4px;
      margin-left: 2px;
      &:hover {
        opacity: 0.7;
      }
    }
    button span {
      display: none;
    }
  }
  div span {
    padding: 0.3rem 0.6rem;
    line-height: 1;
    border-radius: 16px;

    border-radius: var(--border-radius-s);
    color: var(--color-primary);
    background-color: rgba(56, 139, 253, 0.1);
    display: flex;
    font-size: 1rem;
    align-items: center;
    gap: 0.5rem;

    svg {
      width: 12px;
      height: 12px;
    }
  }
  h2 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--color-secondary);
  }
}

.greet {
  margin-bottom: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  h1 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}

.started {
  margin-top: 2rem;
}

.trend {
  margin-top: 2rem;
}

.list {
  margin-top: 1.5rem;
  display: grid;
  gap: 1rem;
  max-height: calc(100% - 90px);
  overflow-y: auto;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem 3rem;
  max-height: 100%;
  overflow-y: auto;
}
.or {
  margin: 2rem auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--color-secondary);
  font-size: 0.875rem;
  width: 100%;
  div {
    flex-grow: 1;
    height: 1px;
    opacity: 0.2;
    background-color: var(--color-secondary);
  }
}
.wizardContent {
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  & > div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
  }
}

.aiModels {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
}

.aiModel {
  box-sizing: border-box;
  padding: 1rem;
  cursor: pointer;
  max-width: 215px;
  text-align: center;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  &:hover {
    border-color: var(--color-primary);
  }
}
.aiLogo {
  display: flex;
  margin-bottom: 0.4rem;
  span {
    display: none;
  }
  img {
    height: 16px;
    max-height: 16px;
    position: relative;
  }
}
.wizardDescription {
  font-weight: 400;
  font-size: 1.1rem;
  max-width: 560px;
  margin: 0 auto;
  margin-top: 1rem;
  text-align: center;
  @media screen and (max-width: 769px) {
    font-size: 0.925rem;
  }
}
.wizardDescriptionShort {
  font-weight: 400;
  font-size: 1.1rem;
  max-width: 560px;
  margin: 0 auto;
  margin-bottom: 1rem;
  text-align: center;
  @media screen and (max-width: 769px) {
    font-size: 0.925rem;
  }
  h3 {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
}
.wizardTitle {
  text-align: center;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.8rem;
  text-align: center;
}
.aiName {
  margin-top: 0.5rem;
  font-weight: 600;
}

.aiDescription {
  margin-top: 0.2rem;
  line-height: 1rem;
  color: var(--color-secondary);
  font-size: 0.785rem;
}
.market {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  max-width: 560px;
  margin: 0 auto;
}

.createSolution {
  border: 3px dashed var(--color-text);
  box-sizing: border-box;
  box-shadow: var(--card-shadow);
  border-radius: var(--border-radius-s);
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.tagItem:has(.active) {
  border: 1px solid red !important;
}
.tagItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 0.825rem;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  padding: 0.2rem 0.5rem;
  border-radius: 20px;
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  white-space: nowrap;
  &:has(.active) {
    border: 1px solid red !important;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    border-color: var(--color-primary);
  }
  span {
    padding-right: 0.2rem;
  }
}

.tagsList {
  margin: auto;
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  max-width: 560px;
  flex-wrap: wrap;
  justify-content: center;

  button {
    white-space: nowrap;
  }
}

.spacesList {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .spacesList {
    margin-top: 1rem;
  }
  .wrapper {
    padding: 1rem 1rem 8rem 1rem;
  }
  .wizardContent {
    padding: 0.5rem 0;
  }
  .wizardTitle {
    font-size: 1.8rem;
  }
  .or {
    margin: 1rem 0;
  }
  .aiModel {
    padding: 1rem 0.7rem;
  }
}

.quickItem {
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease-in;
  &:hover {
    border-color: var(--color-primary);
    transform: translateY(-3px);
  }
}
.quickItem {
  position: relative;
  width: 280px;
  max-width: 100%;
  overflow: hidden;
}

.quickItemIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 35%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.quickItemText {
  padding: 1rem;
  padding-left: 35%;
  margin-left: 1rem;
  box-sizing: border-box;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  span {
    display: block;
    font-weight: 600;
  }
  p {
    margin-top: 0.2rem;
    line-height: 1rem;
    color: var(--color-secondary);
    font-size: 0.785rem;
  }
}

.starterPackContainer {
  margin: 0 auto;
  width: 800px;
  max-width: 800px;
  .quickItem {
    width: 380px;
  }
  h3 {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .quickItem {
    width: 100%;
  }
  .wizardDescription {
    max-width: 100%;
  }
  .aiModels {
    flex-direction: column;
  }

  .wizardContent > div {
    max-width: 100%;
  }
  .starterPackContainer {
    width: 100%;
    max-width: 100%;
  }
}
