.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  max-width: 100%;
  & > * {
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
