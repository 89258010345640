.wrapper {
  box-sizing: border-box;
  // padding: 1rem;
  box-shadow: var(--card-shadow);
  position: relative;
  z-index: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - 57px);
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 51px);
  }
}
.historyActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  button {
    max-height: 32px;
  }
}
.shareHistory {
  button {
    background: var(--color-bg-button-secondary);
    color: var(--color-text);
    height: 32px;
    border-color: var(--color-border-input);
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    font-size: 0.7125rem;
    span {
      display: none;
    }
  }
}
.userLine {
  box-sizing: border-box;
  background-color: var(--color-dashboard-bg-userline);
}
.model {
  font-weight: 600;
  text-transform: uppercase;
}
.attached {
  margin-top: 0rem;
  color: var(--color-secondary);
  font-size: 0.875rem;
  font-weight: 400;
}
.userLineAction {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  button {
    &:hover {
      opacity: 0.7;
    }
  }
}
.contentWrapperTitle {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  min-height: 32px;
  @media screen and (max-width: 768px) {
    line-height: 1.4;
  }
  button {
    &:hover {
      opacity: 0.7;
    }
  }
}
.contentLine {
  box-sizing: border-box;
  padding-bottom: 3rem;
  flex-grow: 1;
}
.contentLineWrapper {
  padding: 1.4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 1.4rem;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    padding: 1rem;
  }
}

.userLineWrapper {
  padding: 1.4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 1.4rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    gap: 1rem;
  }
}

.endLine {
  text-align: center;
  font-weight: bold;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding-bottom: 7rem;
  }
}

.historySettingsContent {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  flex-wrap: wrap;
}

.contentStreamLine {
  box-sizing: border-box;
  margin-top: 1rem;
}

.contentWrapper {
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.contentWrapperTitle {
  font-size: 0.875rem;
  font-weight: 500;
  span {
    margin-left: 0.4rem;
    font-size: 0.785rem;
    font-weight: 400;
    color: var(--color-secondary);
  }
}

.settingsItemLabel {
  text-transform: capitalize;
}

.settingsContent {
  box-sizing: border-box;
  padding: 0.3rem;

  // border: 1px solid var(--color-card-border);

  border-radius: var(--border-radius-s);
  // max-height: 150px;
  // overflow-y: auto;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.startOver {
  font-weight: 500;
  color: var(--color-primary);
  margin-left: 4px;
}
.historySettings {
  width: 100%;
}
.historySettingsDate {
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  button {
    background-color: transparent;
  }
  @media screen and (max-width: 768px) {
    line-height: 1.4;
    margin-bottom: 0.3rem;
  }
  span {
    margin-left: 0.4rem;
    font-size: 0.785rem;
    font-weight: 400;
    color: var(--color-secondary);
  }
}
.avatar {
  display: flex;
  align-items: center;
  img:last-child {
    margin-left: -10px;

    background: var(--color-white);
    @media screen and (max-width: 768px) {
      margin-left: -17px;
    }
  }
  img {
    min-width: 30px;
    border: 2px solid var(--color-white);
  }
  span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: var(--color-dashboard-bg-secondary);
  }
}
.streamline {
  position: relative;
  max-width: 720px;
  box-sizing: border-box;
  padding: 0 2rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.contentMD {
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  font-size: 1rem;
  a {
    color: var(--color-primary);
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 1rem;
    white-space: pre-line;
  }
  h1 {
    margin-bottom: 0.7rem;
    font-weight: 600;
    font-size: 1.4rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.925rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.6rem 15px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 12px 15px;
      text-align: left;
      font-weight: 600;
      background-color: var(--color-secondary);
    }
  }
}

.contentMD ul,
.contentMD ol {
  padding: 1.2rem;
  padding-top: 0;
  list-style: unset;
  padding-left: 3.2rem;
}
.contentMD ol {
  list-style: numeric;
}
.contentMD li + li {
  margin-top: 0.5rem;
}

.blinkIcon {
  display: inline-block;
  width: 4px;
  background-color: var(--color-primary);
  margin-left: 0.1rem;
  height: 12px;
  animation: cursor-blink 1s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}
.element {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.1rem 0.3rem;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.925rem;
}
.username {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--color-text);
  img {
    margin-right: 0.5rem;
  }
}

.userInput {
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-s);
  background: var(--color-dashboard-bg-secondary);
  color: var(--color-secondary);
  margin-bottom: 2rem;
  font-weight: 500;
  span {
    padding-right: 0.2rem;
  }
}

.imagesBox {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  //grid-auto-rows: 0fr;
  img {
    width: 100%;
    height: auto;
  }
}
.imageItem {
  position: relative;
  &:hover a {
    opacity: 0.8;
  }
}
.downloadImage {
  position: absolute;
  opacity: 0;
  top: 10px;
  right: 10px;
  //opacity: 0.8;
  transition: all 0.3s ease-in-out;
  button {
    svg {
      margin-left: 0;
    }
    span {
      display: none;
    }
  }
  &:hover {
    opacity: 0.3 !important;
  }
}

.modalWrapper {
  margin-top: 1rem;
  button {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
